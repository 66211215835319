export const ArrowsIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M7.17754 11.3677C7.23671 11.4268 7.28364 11.497 7.31566 11.5742C7.34769 11.6515 7.36417 11.7343 7.36417 11.8179C7.36417 11.9015 7.34769 11.9843 7.31566 12.0616C7.28364 12.1388 7.23671 12.209 7.17754 12.2681L4.63222 14.8134C4.57312 14.8725 4.50294 14.9195 4.42569 14.9515C4.34844 14.9835 4.26563 15 4.18201 15C4.09839 15 4.01558 14.9835 3.93833 14.9515C3.86108 14.9195 3.7909 14.8725 3.73181 14.8134L1.18648 12.2681C1.12736 12.209 1.08046 12.1388 1.04846 12.0616C1.01647 11.9843 1 11.9015 1 11.8179C1 11.7343 1.01647 11.6515 1.04846 11.5743C1.08046 11.497 1.12736 11.4268 1.18648 11.3677C1.30588 11.2483 1.46783 11.1812 1.63669 11.1812C1.7203 11.1812 1.80309 11.1977 1.88033 11.2297C1.95758 11.2617 2.02777 11.3086 2.08689 11.3677L3.54568 12.8273V1.63682C3.54568 1.46806 3.61272 1.30621 3.73206 1.18687C3.85139 1.06754 4.01325 1.0005 4.18201 1.0005C4.35078 1.0005 4.51263 1.06754 4.63197 1.18687C4.7513 1.30621 4.81834 1.46806 4.81834 1.63682V12.8273L6.27713 11.3677C6.33623 11.3086 6.40641 11.2616 6.48366 11.2296C6.56091 11.1976 6.64371 11.1811 6.72734 11.1811C6.81096 11.1811 6.89376 11.1976 6.97101 11.2296C7.04826 11.2616 7.11844 11.3086 7.17754 11.3677ZM14.8135 3.7319L12.2682 1.18662C12.2091 1.12746 12.1389 1.08053 12.0617 1.0485C11.9844 1.01648 11.9016 1 11.818 1C11.7344 1 11.6516 1.01648 11.5743 1.0485C11.4971 1.08053 11.4269 1.12746 11.3678 1.18662L8.82246 3.7319C8.70306 3.8513 8.63598 4.01324 8.63598 4.18209C8.63598 4.35095 8.70306 4.51289 8.82246 4.63229C8.94186 4.75169 9.1038 4.81876 9.27266 4.81876C9.44152 4.81876 9.60347 4.75169 9.72287 4.63229L11.1817 3.17273V14.3632C11.1817 14.5319 11.2487 14.6938 11.368 14.8131C11.4874 14.9325 11.6492 14.9995 11.818 14.9995C11.9868 14.9995 12.1486 14.9325 12.2679 14.8131C12.3873 14.6938 12.4543 14.5319 12.4543 14.3632V3.17273L13.9131 4.63229C14.0325 4.75169 14.1945 4.81876 14.3633 4.81876C14.5322 4.81876 14.6941 4.75169 14.8135 4.63229C14.9329 4.51289 15 4.35095 15 4.18209C15 4.01324 14.9329 3.8513 14.8135 3.7319Z"
        fill="black"
      />
    </svg>
  );
};
