export const TimeIcon = () => {
  return (
    <svg width="88" height="88" viewBox="0 0 88 88" fill="none">
      <path
        d="M52.2552 54.8114L37.7143 46.0952V20.9524H44V42.9524L56.9067 50.621C55.1886 51.7943 53.5962 53.2191 52.2552 54.8114ZM46.4305 75.0933C44.9219 75.3029 43.4552 75.4286 41.9048 75.4286C23.3829 75.4286 8.38095 60.4267 8.38095 41.9048C8.38095 23.3829 23.3829 8.38095 41.9048 8.38095C60.4267 8.38095 75.4286 23.3829 75.4286 41.9048C75.4286 43.4552 75.3029 44.9219 75.0933 46.4305C77.9848 46.8495 80.6667 47.7714 83.1391 49.1124C83.5581 46.7657 83.8095 44.3771 83.8095 41.9048C83.8095 18.8571 64.9524 0 41.9048 0C18.8571 0 0 18.8571 0 41.9048C0 64.9524 18.7314 83.8095 41.9048 83.8095C44.3771 83.8095 46.7657 83.5581 49.1124 83.1391C47.7714 80.6667 46.8495 77.9848 46.4305 75.0933ZM67.0476 54.4762V67.0476H54.4762V75.4286H67.0476V88H75.4286V75.4286H88V67.0476H75.4286V54.4762H67.0476Z"
        fill="url(#paint0_linear_954_34382)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_954_34382"
          x1="0"
          y1="88"
          x2="88"
          y2="0"
          gradientUnits="userSpaceOnUse">
          <stop offset="0.244792" stopColor="#125C4F" />
          <stop offset="0.65625" stopColor="#197E6C" />
          <stop offset="1" stopColor="#24B298" />
        </linearGradient>
      </defs>
    </svg>
  );
};
