import {FC} from 'react';

type TProps = {
  size?: string;
  margin?: boolean;
  dashboard?: boolean;
};
export const SearchIcon: FC<TProps> = ({
  size = '28',
  margin = false,
  dashboard,
}) => {
  return dashboard ? (
    <svg width="18" height="19" viewBox="0 0 24 23" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6875 2C6.16566 2 2.5 5.66566 2.5 10.1875C2.5 14.7093 6.16566 18.375 10.6875 18.375C15.2093 18.375 18.875 14.7093 18.875 10.1875C18.875 5.66566 15.2093 2 10.6875 2ZM0.5 10.1875C0.5 4.56109 5.06109 0 10.6875 0C16.3139 0 20.875 4.56109 20.875 10.1875C20.875 12.6405 20.008 14.8911 18.5636 16.6494L23.2071 21.2929C23.5976 21.6834 23.5976 22.3166 23.2071 22.7071C22.8166 23.0976 22.1834 23.0976 21.7929 22.7071L17.1494 18.0636C15.3911 19.508 13.1405 20.375 10.6875 20.375C5.06109 20.375 0.5 15.8139 0.5 10.1875Z"
        fill="#0D0C07"
      />
    </svg>
  ) : (
    <svg
      width={size}
      height={size}
      viewBox="0 0 28 28"
      fill="none"
      style={{marginRight: margin ? '12px' : '0px'}}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8333 4.66659C8.32293 4.66659 4.66659 8.32293 4.66659 12.8333C4.66659 17.3436 8.32293 20.9999 12.8333 20.9999C17.3436 20.9999 20.9999 17.3436 20.9999 12.8333C20.9999 8.32293 17.3436 4.66659 12.8333 4.66659ZM2.33325 12.8333C2.33325 7.03426 7.03426 2.33325 12.8333 2.33325C18.6322 2.33325 23.3333 7.03426 23.3333 12.8333C23.3333 18.6322 18.6322 23.3333 12.8333 23.3333C7.03426 23.3333 2.33325 18.6322 2.33325 12.8333Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.6 18.6C19.0556 18.1444 19.7943 18.1444 20.2499 18.6L25.3249 23.675C25.7805 24.1306 25.7805 24.8693 25.3249 25.3249C24.8693 25.7805 24.1306 25.7805 23.675 25.3249L18.6 20.2499C18.1444 19.7943 18.1444 19.0556 18.6 18.6Z"
        fill="black"
      />
    </svg>
  );
};
