export const ListSettingsIcon = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none">
      <g clipPath="url(#clip0_218_8428)">
        <path
          d="M20.75 17.75H19.25V16.25H20V13.25H17V14H15.5V12.5C15.5 12.3011 15.579 12.1103 15.7197 11.9697C15.8603 11.829 16.0511 11.75 16.25 11.75H20.75C20.9489 11.75 21.1397 11.829 21.2803 11.9697C21.421 12.1103 21.5 12.3011 21.5 12.5V17C21.5 17.1989 21.421 17.3897 21.2803 17.5303C21.1397 17.671 20.9489 17.75 20.75 17.75Z"
          fill="black"
        />
        <path
          d="M17 21.5H12.5C12.3011 21.5 12.1103 21.421 11.9697 21.2804C11.829 21.1397 11.75 20.949 11.75 20.75V16.25C11.75 16.0511 11.829 15.8604 11.9697 15.7197C12.1103 15.5791 12.3011 15.5 12.5 15.5H17C17.1989 15.5 17.3897 15.5791 17.5303 15.7197C17.671 15.8604 17.75 16.0511 17.75 16.25V20.75C17.75 20.949 17.671 21.1397 17.5303 21.2804C17.3897 21.421 17.1989 21.5 17 21.5ZM13.25 20H16.25V17H13.25V20ZM10.25 13.8935C9.70808 13.7523 9.21662 13.462 8.83142 13.0554C8.44623 12.6489 8.18274 12.1426 8.07086 11.5938C7.95899 11.0451 8.00322 10.476 8.19853 9.95115C8.39385 9.42629 8.73242 8.96671 9.17579 8.6246C9.61917 8.28249 10.1496 8.07156 10.7068 8.01575C11.2641 7.95994 11.8258 8.0615 12.3282 8.30889C12.8306 8.55628 13.2536 8.93959 13.5491 9.41529C13.8446 9.89099 14.0008 10.44 14 11H15.5C15.5009 10.1419 15.2563 9.3014 14.7953 8.57764C14.3342 7.85389 13.6758 7.27706 12.8977 6.91518C12.1195 6.5533 11.2542 6.42145 10.4036 6.53518C9.55303 6.64891 8.75271 7.00348 8.097 7.55708C7.4413 8.11068 6.95757 8.84023 6.70283 9.65969C6.4481 10.4792 6.43299 11.3544 6.65928 12.1822C6.88558 13.0099 7.34384 13.7557 7.98005 14.3316C8.61625 14.9075 9.40385 15.2895 10.25 15.4325V13.8935Z"
          fill="black"
        />
        <path
          d="M20.6675 9.1625L18.935 10.685L17.87 9.62L19.6775 8.03L17.9075 4.97L15.3275 5.84C14.7231 5.33695 14.0386 4.93892 13.3025 4.6625L12.77 2H9.22999L8.69749 4.6625C7.95544 4.93119 7.26719 5.32992 6.66499 5.84L4.09249 4.97L2.32249 8.03L4.36249 9.8225C4.22373 10.5988 4.22373 11.3937 4.36249 12.17L2.32249 13.97L4.09249 17.03L6.67249 16.16C7.27684 16.663 7.96135 17.0611 8.69749 17.3375L9.22999 20H10.25V21.5H9.22999C8.88314 21.4997 8.5471 21.3792 8.27908 21.1591C8.01106 20.9389 7.82762 20.6327 7.75999 20.2925L7.37749 18.4025C7.03793 18.2369 6.70971 18.049 6.39499 17.84L4.57249 18.455C4.41751 18.5054 4.25546 18.5308 4.09249 18.53C3.82903 18.5318 3.56987 18.4633 3.34177 18.3314C3.11367 18.1996 2.92491 18.0092 2.79499 17.78L1.02499 14.72C0.849926 14.4193 0.784875 14.067 0.840973 13.7236C0.897071 13.3801 1.07083 13.0669 1.33249 12.8375L2.77249 11.5775C2.75749 11.3825 2.74999 11.195 2.74999 11C2.74999 10.805 2.76499 10.6175 2.77999 10.43L1.33249 9.1625C1.07083 8.93309 0.897071 8.61985 0.840973 8.27642C0.784875 7.93299 0.849926 7.58074 1.02499 7.28L2.79499 4.22C2.92491 3.9908 3.11367 3.80044 3.34177 3.66859C3.56987 3.53675 3.82903 3.46819 4.09249 3.47C4.25546 3.46925 4.41751 3.49457 4.57249 3.545L6.38749 4.16C6.70478 3.95098 7.0355 3.76307 7.37749 3.5975L7.75999 1.7075C7.82762 1.36731 8.01106 1.06107 8.27908 0.84091C8.5471 0.620751 8.88314 0.500279 9.22999 0.5H12.77C13.1168 0.500279 13.4529 0.620751 13.7209 0.84091C13.9889 1.06107 14.1723 1.36731 14.24 1.7075L14.6225 3.5975C14.962 3.76311 15.2903 3.95102 15.605 4.16L17.4275 3.545C17.5825 3.49457 17.7445 3.46925 17.9075 3.47C18.1709 3.46819 18.4301 3.53675 18.6582 3.66859C18.8863 3.80044 19.0751 3.9908 19.205 4.22L20.975 7.28C21.15 7.58074 21.2151 7.93299 21.159 8.27642C21.1029 8.61985 20.9291 8.93309 20.6675 9.1625Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_218_8428">
          <rect width="22" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
