export const LotIcon = () => {
  return (
    <svg width="16" height="13" viewBox="0 0 16 13" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.25 1.75V11.25H13.75V1.75H2.25ZM0.75 1.55C0.75 0.842866 1.31754 0.25 2.04167 0.25H13.9583C14.6825 0.25 15.25 0.842866 15.25 1.55V11.45C15.25 12.1571 14.6825 12.75 13.9583 12.75H2.04167C1.31754 12.75 0.75 12.1571 0.75 11.45V1.55ZM9.41667 3.2C9.41667 2.78579 9.75245 2.45 10.1667 2.45H12.3333C12.7475 2.45 13.0833 2.78579 13.0833 3.2V5.4C13.0833 5.81421 12.7475 6.15 12.3333 6.15C11.9191 6.15 11.5833 5.81421 11.5833 5.4V3.95H10.1667C9.75245 3.95 9.41667 3.61421 9.41667 3.2ZM3.66667 6.85C4.08088 6.85 4.41667 7.18579 4.41667 7.6V9.05H5.83333C6.24755 9.05 6.58333 9.38579 6.58333 9.8C6.58333 10.2142 6.24755 10.55 5.83333 10.55H3.66667C3.25245 10.55 2.91667 10.2142 2.91667 9.8V7.6C2.91667 7.18579 3.25245 6.85 3.66667 6.85Z"
        fill="#B3B3B3"
      />
    </svg>
  );
};
