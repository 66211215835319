export const DotsIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M8 9.18421C8.69036 9.18421 9.25 8.65402 9.25 8C9.25 7.34598 8.69036 6.81579 8 6.81579C7.30964 6.81579 6.75 7.34598 6.75 8C6.75 8.65402 7.30964 9.18421 8 9.18421Z"
        fill="#262626"
      />
      <path
        d="M8 2.86842C8.69036 2.86842 9.25 2.33823 9.25 1.68421C9.25 1.03019 8.69036 0.5 8 0.5C7.30964 0.5 6.75 1.03019 6.75 1.68421C6.75 2.33823 7.30964 2.86842 8 2.86842Z"
        fill="#262626"
      />
      <path
        d="M8 15.5C8.69036 15.5 9.25 14.9698 9.25 14.3158C9.25 13.6618 8.69036 13.1316 8 13.1316C7.30964 13.1316 6.75 13.6618 6.75 14.3158C6.75 14.9698 7.30964 15.5 8 15.5Z"
        fill="#262626"
      />
    </svg>
  );
};
