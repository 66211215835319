export const VisaIcon = () => {
  return (
    <svg width="40" height="24" viewBox="0 0 40 24" fill="none">
      <rect
        x="0.75"
        y="0.25"
        width="38.5"
        height="23.5"
        rx="3.75"
        fill="white"
      />
      <path
        d="M20.4406 10.5575C20.426 11.6719 21.4691 12.2938 22.2548 12.6636C23.0621 13.0429 23.3332 13.2862 23.33 13.6255C23.324 14.1447 22.6861 14.3738 22.0892 14.3827C21.0477 14.3983 20.4421 14.1112 19.9607 13.894L19.5856 15.5894C20.0686 15.8043 20.9629 15.9918 21.8903 16C24.0674 16 25.4916 14.9622 25.4994 13.3532C25.5079 11.3111 22.5745 11.198 22.5945 10.2853C22.6014 10.0085 22.8749 9.71317 23.4741 9.63807C23.7708 9.60014 24.5896 9.5711 25.5179 9.98394L25.8822 8.34364C25.383 8.16809 24.7414 8 23.9425 8C21.8935 8 20.4523 9.05192 20.4406 10.5575ZM29.3833 8.14129C28.9858 8.14129 28.6508 8.36522 28.5013 8.70886L25.3915 15.8795H27.567L27.9999 14.7241H30.6582L30.9093 15.8795H32.8267L31.1535 8.14129H29.3833ZM29.6877 10.2317L30.3155 13.1375H28.5961L29.6877 10.2317ZM17.803 8.14138L16.0883 15.8794H18.1613L19.8753 8.14119H17.803M14.7364 8.14119L12.5787 13.4082L11.7059 8.92989C11.6035 8.42996 11.199 8.14129 10.7499 8.14129H7.22274L7.17334 8.36599C7.89745 8.51773 8.72016 8.76247 9.21859 9.02434C9.52363 9.1843 9.61061 9.32414 9.71082 9.70426L11.364 15.8795H13.5546L16.9132 8.14129H14.7364"
        fill="url(#paint0_linear_2568_28820)"
      />
      <rect
        x="0.75"
        y="0.25"
        width="38.5"
        height="23.5"
        rx="3.75"
        stroke="#E6E6E6"
        strokeWidth="0.5"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2568_28820"
          x1="18.9672"
          y1="16.1605"
          x2="19.1908"
          y2="7.94392"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#222357" />
          <stop offset="1" stopColor="#254AA5" />
        </linearGradient>
      </defs>
    </svg>
  );
};
