import {FC} from 'react';

type TProps = {
  size: string;
};

export const YoutubeIcon: FC<TProps> = ({size = '50'}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 50 50" fill="none">
      <g filter="url(#filter0_d_1178_31516)">
        <rect x="9" y="3.03345" width="32" height="32" rx="16" fill="#FF0000" />
      </g>
      <path
        d="M25.4112 25.7345L21.6034 25.6634C20.3705 25.6386 19.1345 25.6881 17.9258 25.4311C16.0871 25.0473 15.9569 23.1651 15.8205 21.5864C15.6327 19.3668 15.7054 17.107 16.0599 14.9059C16.26 13.6709 17.0474 12.934 18.2651 12.8538C22.3758 12.5628 26.5139 12.5973 30.6155 12.733C31.0486 12.7455 31.4848 12.8135 31.9119 12.891C34.0204 13.2686 34.0718 15.4015 34.2085 17.197C34.3448 19.0111 34.2872 20.8344 34.0267 22.6361C33.8177 24.1278 33.4178 25.3788 31.7302 25.4996C29.6157 25.6575 27.5497 25.7846 25.4293 25.7441C25.4294 25.7345 25.4172 25.7345 25.4112 25.7345ZM23.1725 21.9579C24.766 21.0229 26.329 20.1036 27.9134 19.1749C26.3169 18.2399 24.7569 17.3206 23.1725 16.3919V21.9579Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_d_1178_31516"
          x="0.566045"
          y="0.733278"
          width="48.8679"
          height="48.8679"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="6.13379" />
          <feGaussianBlur stdDeviation="4.21698" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 0 0 0 0 0 0 0 0 0 0.21 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1178_31516"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1178_31516"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
