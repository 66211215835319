export const BathsIcon = () => {
  return (
    <svg width="16" height="15" viewBox="0 0 16 15" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.74999 2.25C4.62793 2.25 4.50407 2.3003 4.4076 2.40096C4.31009 2.50271 4.24999 2.64727 4.24999 2.80434V5.44569H7.78535C7.88191 5.14308 8.16536 4.92393 8.49998 4.92393H12.5C12.8346 4.92393 13.1181 5.14308 13.2146 5.44569H14.5C14.8421 5.44569 15.1633 5.58778 15.395 5.82957C15.6257 6.07028 15.75 6.39019 15.75 6.71742V8.80437C15.75 9.82346 15.3623 10.8069 14.6628 11.5368C14.0206 12.207 13.1628 12.6131 12.2501 12.6762V13.5C12.2501 13.9142 11.9143 14.25 11.5001 14.25C11.0859 14.25 10.7501 13.9142 10.7501 13.5V12.6848H5.25003V13.5C5.25003 13.9142 4.91424 14.25 4.50003 14.25C4.08581 14.25 3.75003 13.9142 3.75003 13.5V12.6762C2.83727 12.6132 1.97941 12.207 1.33719 11.5368C0.637669 10.8069 0.25 9.82346 0.25 8.80437V6.71742C0.25 6.39019 0.374277 6.07028 0.604957 5.82957C0.836677 5.58778 1.15793 5.44569 1.5 5.44569H2.74999V2.80434C2.74999 2.26955 2.95329 1.75058 3.32462 1.3631C3.69699 0.974542 4.20901 0.75 4.74999 0.75C5.29097 0.75 5.803 0.974542 6.17537 1.3631C6.5467 1.75058 6.74999 2.26955 6.74999 2.80434C6.74999 3.21855 6.41421 3.55434 5.99999 3.55434C5.58578 3.55434 5.24999 3.21855 5.24999 2.80434C5.24999 2.64727 5.1899 2.50271 5.09239 2.40096C4.99592 2.3003 4.87206 2.25 4.74999 2.25ZM11.75 6.42393H9.24998V8.05435H11.75V6.42393ZM7.74998 6.94569V8.80435C7.74998 9.21856 8.08577 9.55435 8.49998 9.55435H12.5C12.9142 9.55435 13.25 9.21856 13.25 8.80435V6.94569H14.25V8.80437C14.25 9.44575 14.0055 10.0548 13.5798 10.499C13.1552 10.9421 12.5862 11.1848 12 11.1848H4C3.41381 11.1848 2.84483 10.9421 2.42017 10.499C1.99447 10.0548 1.75 9.44575 1.75 8.80437V6.94569H7.74998Z"
        fill="#B3B3B3"
      />
    </svg>
  );
};
