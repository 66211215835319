import {FC} from 'react';

type TProps = {
  width?: string;
  height?: string;
};

export const ArrowUpIcon: FC<TProps> = ({width = '20', height = '12'}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 20 12" fill="none">
      <path
        d="M18 10L10 2L2 10"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
