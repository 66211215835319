import {FC} from 'react';

export const PinIcon: FC<{active: boolean}> = ({active}) => {
  return active ? (
    <svg width="15" height="16" viewBox="0 0 15 16" fill="none">
      <path
        d="M14.6682 6.69727L10.5515 10.8202C10.8682 11.7153 11.0019 13.2095 9.62262 15.0489C9.52489 15.178 9.40101 15.2849 9.25912 15.3627C9.11722 15.4404 8.96051 15.4872 8.79928 15.5H8.72187C8.4236 15.4987 8.13787 15.3797 7.92667 15.1688L4.53477 11.7717L2.12103 14.1962C2.01343 14.3004 1.8696 14.3586 1.71992 14.3586C1.57023 14.3586 1.42641 14.3004 1.3188 14.1962C1.21308 14.0893 1.15378 13.9449 1.15378 13.7944C1.15378 13.644 1.21308 13.4996 1.3188 13.3927L3.73957 10.9753L0.333601 7.56415C0.220616 7.45266 0.13259 7.31838 0.0753629 7.17023C0.0181357 7.02207 -0.00698543 6.86342 0.00166552 6.7048C0.0103165 6.54618 0.0525418 6.39122 0.125541 6.25019C0.19854 6.10916 0.300645 5.98528 0.425084 5.88677C2.21252 4.44197 3.92254 4.73093 4.67551 4.96351L8.80631 0.82644C9.01829 0.617258 9.30393 0.5 9.60151 0.5C9.8991 0.5 10.1847 0.617258 10.3967 0.82644L14.6682 5.10447C14.7734 5.20867 14.8568 5.3327 14.9137 5.4694C14.9707 5.6061 15 5.75275 15 5.90087C15 6.04899 14.9707 6.19564 14.9137 6.33234C14.8568 6.46903 14.7734 6.59307 14.6682 6.69727Z"
        fill="#625A28"
      />
    </svg>
  ) : (
    <svg width="15" height="16" viewBox="0 0 15 16" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.97491 0.849274C9.13126 0.783751 9.29909 0.75 9.46863 0.75C9.63817 0.75 9.806 0.783752 9.96234 0.849274C10.1176 0.914336 10.2584 1.00943 10.3768 1.12904L14.3715 5.12734C14.4911 5.24581 14.586 5.38671 14.6509 5.54194C14.7163 5.69825 14.75 5.86599 14.75 6.03541C14.75 6.20482 14.7163 6.37256 14.6509 6.52887C14.586 6.68409 14.4911 6.82498 14.3715 6.94344L10.6142 10.7042C11.1761 12.449 10.1835 14.055 9.66445 14.7415C9.55421 14.8877 9.41384 15.0085 9.25278 15.0958C9.09103 15.1834 8.91227 15.2351 8.72869 15.2472C8.54512 15.2594 8.3611 15.2317 8.18921 15.1662C8.01856 15.1011 7.86388 15.0002 7.73557 14.8703L4.71867 11.8507L2.61037 13.9609C2.31761 14.2539 1.84273 14.2541 1.54971 13.9614C1.25669 13.6686 1.25647 13.1937 1.54924 12.9007L3.65848 10.7896L0.62791 7.75632C0.627438 7.75586 0.626967 7.75539 0.626496 7.75492C0.499691 7.6287 0.400814 7.47726 0.336233 7.31044C0.271413 7.14301 0.242587 6.9638 0.251619 6.78449C0.260652 6.60519 0.307342 6.42977 0.38867 6.26969C0.469999 6.10961 0.584153 5.96842 0.723726 5.85538L0.72413 5.85505C1.86292 4.93399 2.99524 4.72268 3.85741 4.76121C4.22911 4.77782 4.54535 4.84035 4.78625 4.90663L8.56043 1.12909C8.67879 1.00945 8.81964 0.914345 8.97491 0.849274ZM4.6521 6.44456C4.65158 6.44432 4.65082 6.44397 4.6498 6.4435C4.64469 6.44117 4.63339 6.43612 4.61629 6.42913C4.582 6.4151 4.52506 6.39352 4.4486 6.37031C4.29494 6.32368 4.06775 6.27211 3.79043 6.25972C3.2926 6.23747 2.60901 6.33895 1.86421 6.87147L5.24709 10.2574C5.24765 10.2579 5.24821 10.2585 5.24877 10.259C5.24964 10.2599 5.25052 10.2608 5.25139 10.2617L8.61661 13.6299C9.10697 12.91 9.55766 11.8494 9.06216 10.8618C8.91727 10.573 8.97359 10.2239 9.20195 9.99538L13.1584 6.03541L9.46863 2.34235L5.51312 6.30138C5.28613 6.52856 4.93993 6.58586 4.6521 6.44456ZM13.8425 5.65906L13.8426 5.65903L13.8425 5.65906Z"
        fill="#0D0C07"
      />
    </svg>
  );
};
