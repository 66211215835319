export const ContactEmailIcon = () => {
  return (
    <svg width="15" height="13" viewBox="0 0 15 13" fill="none">
      <path
        d="M2.4 0.0334473C1.76348 0.0334473 1.15303 0.286304 0.702944 0.736391C0.252856 1.18648 0 1.79693 0 2.43345L0 2.67465L7.2 6.55185L14.4 2.67585V2.43345C14.4 1.79693 14.1471 1.18648 13.6971 0.736391C13.247 0.286304 12.6365 0.0334473 12 0.0334473L2.4 0.0334473ZM14.4 4.03785L7.4844 7.76145C7.39699 7.8085 7.29927 7.83313 7.2 7.83313C7.10073 7.83313 7.00301 7.8085 6.9156 7.76145L0 4.03785L0 9.63345C0 10.27 0.252856 10.8804 0.702944 11.3305C1.15303 11.7806 1.76348 12.0334 2.4 12.0334H12C12.6365 12.0334 13.247 11.7806 13.6971 11.3305C14.1471 10.8804 14.4 10.27 14.4 9.63345V4.03785Z"
        fill="#FFFDFA"
      />
    </svg>
  );
};
