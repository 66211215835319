import {FC} from 'react';

type TProps = {
  opacity?: number;
  small?: boolean;
  notes?: boolean;
  table?: boolean;
  right?: string;
};

export const EditIcon: FC<TProps> = ({
  opacity = 0.5,
  small = false,
  notes,
  table,
  right,
}) => {
  return notes ? (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M5.52908 14.5H2.07559C1.92293 14.5 1.77653 14.4394 1.66859 14.3314C1.56065 14.2235 1.5 14.0771 1.5 13.9244V10.7084C1.49974 10.6336 1.51422 10.5596 1.54262 10.4904C1.57101 10.4213 1.61276 10.3584 1.66548 10.3054L10.2992 1.67171C10.3528 1.61733 10.4166 1.57414 10.487 1.54466C10.5574 1.51518 10.633 1.5 10.7093 1.5C10.7856 1.5 10.8612 1.51518 10.9316 1.54466C11.002 1.57414 11.0659 1.61733 11.1194 1.67171L14.3283 4.88058C14.3827 4.93414 14.4259 4.99798 14.4553 5.06839C14.4848 5.13879 14.5 5.21436 14.5 5.29069C14.5 5.36701 14.4848 5.44258 14.4553 5.51299C14.4259 5.58339 14.3827 5.64723 14.3283 5.70079L5.52908 14.5ZM5.52908 14.5L14.1628 14.5M8.40698 3.56393L12.4361 7.59301"
        stroke="#0D0C07"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : table ? (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      style={{right, position: 'absolute'}}>
      <path
        d="M4.93435 14.0957H1.48086C1.32821 14.0957 1.1818 14.0351 1.07386 13.9271C0.965919 13.8192 0.905277 13.6728 0.905277 13.5201V10.3041C0.905015 10.2293 0.919496 10.1553 0.947891 10.0861C0.976287 10.017 1.01804 9.95413 1.07076 9.90115L9.70449 1.26742C9.75804 1.21303 9.82188 1.16984 9.89229 1.14036C9.96269 1.11088 10.0383 1.0957 10.1146 1.0957C10.1909 1.0957 10.2665 1.11088 10.3369 1.14036C10.4073 1.16984 10.4711 1.21303 10.5247 1.26742L13.7336 4.47629C13.7879 4.52984 13.8311 4.59368 13.8606 4.66409C13.8901 4.73449 13.9053 4.81006 13.9053 4.88639C13.9053 4.96272 13.8901 5.03828 13.8606 5.10869C13.8311 5.17909 13.7879 5.24293 13.7336 5.29649L4.93435 14.0957ZM4.93435 14.0957L13.5681 14.0957M7.81226 3.15963L11.8413 7.18871"
        stroke="#0D0C07"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : small ? (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M10.2992 1.67171L8.40698 3.56393L12.4361 7.59301L14.3283 5.70079C14.3827 5.64723 14.4259 5.58339 14.4553 5.51299C14.4848 5.44258 14.5 5.36701 14.5 5.29069C14.5 5.21436 14.4848 5.13879 14.4553 5.06839C14.4259 4.99798 14.3827 4.93414 14.3283 4.88058L11.1194 1.67171C11.0659 1.61733 11.002 1.57414 10.9316 1.54466C10.8612 1.51518 10.7856 1.5 10.7093 1.5C10.633 1.5 10.5574 1.51518 10.487 1.54466C10.4166 1.57414 10.3528 1.61733 10.2992 1.67171Z"
        fill="#0D0C07"
      />
      <path
        d="M5.52908 14.5H2.07559C1.92293 14.5 1.77653 14.4394 1.66859 14.3314C1.56065 14.2235 1.5 14.0771 1.5 13.9244V10.7084C1.49974 10.6336 1.51422 10.5596 1.54262 10.4904C1.57101 10.4213 1.61276 10.3584 1.66548 10.3054L10.2992 1.67171M5.52908 14.5L14.3283 5.70079M5.52908 14.5L14.1628 14.5M10.2992 1.67171C10.3528 1.61733 10.4166 1.57414 10.487 1.54466C10.5574 1.51518 10.633 1.5 10.7093 1.5C10.7856 1.5 10.8612 1.51518 10.9316 1.54466C11.002 1.57414 11.0659 1.61733 11.1194 1.67171L14.3283 4.88058C14.3827 4.93414 14.4259 4.99798 14.4553 5.06839C14.4848 5.13879 14.5 5.21436 14.5 5.29069C14.5 5.36701 14.4848 5.44258 14.4553 5.51299C14.4259 5.58339 14.3827 5.64723 14.3283 5.70079M10.2992 1.67171L8.40698 3.56393L12.4361 7.59301L14.3283 5.70079"
        stroke="#0D0C07"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      opacity={opacity}>
      <path
        d="M14.141 4.01623L16.259 6.13323M15.503 2.15123L9.77599 7.87823C9.48008 8.17372 9.27827 8.55021 9.19599 8.96023L8.66699 11.6082L11.315 11.0782C11.725 10.9962 12.101 10.7952 12.397 10.4992L18.124 4.77223C18.2961 4.60013 18.4326 4.39582 18.5257 4.17096C18.6189 3.94611 18.6668 3.70511 18.6668 3.46173C18.6668 3.21834 18.6189 2.97734 18.5257 2.75249C18.4326 2.52763 18.2961 2.32332 18.124 2.15123C17.9519 1.97913 17.7476 1.84261 17.5227 1.74947C17.2979 1.65634 17.0569 1.6084 16.8135 1.6084C16.5701 1.6084 16.3291 1.65634 16.1043 1.74947C15.8794 1.84261 15.6751 1.97913 15.503 2.15123V2.15123Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.667 13.6084V16.6084C16.667 17.1388 16.4563 17.6475 16.0812 18.0226C15.7061 18.3977 15.1974 18.6084 14.667 18.6084H3.66699C3.13656 18.6084 2.62785 18.3977 2.25278 18.0226C1.87771 17.6475 1.66699 17.1388 1.66699 16.6084V5.6084C1.66699 5.07797 1.87771 4.56926 2.25278 4.19418C2.62785 3.81911 3.13656 3.6084 3.66699 3.6084H6.66699"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
