import {FC} from 'react';

export const UploadIcon: FC<{white?: boolean}> = ({white}) => {
  return white ? (
    <svg
      width="12"
      height="16"
      viewBox="0 0 12 16"
      fill="none"
      style={{marginRight: '8px'}}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.56348 2.18163V13.8184H10.437V6.01318H7.55623C7.14202 6.01318 6.80623 5.67739 6.80623 5.26318V2.18163H1.56348ZM8.30623 3.31351L9.44303 4.51318H8.30623V3.31351ZM0.420987 1.07608C0.655409 0.828691 0.982249 0.681631 1.33215 0.681631H7.55623C7.76215 0.681631 7.95901 0.766292 8.10064 0.915761L11.7314 4.74731C11.8634 4.88663 11.937 5.07125 11.937 5.26318V14.021C11.937 14.3524 11.8126 14.6779 11.5794 14.9239C11.345 15.1713 11.0182 15.3184 10.6683 15.3184H1.33215C0.982251 15.3184 0.655411 15.1713 0.420987 14.9239C0.187863 14.6779 0.0634766 14.3524 0.0634766 14.021V1.979C0.0634766 1.64755 0.187864 1.3221 0.420987 1.07608ZM6.00021 6.70264C6.20613 6.70264 6.40299 6.7873 6.54462 6.93677L8.35998 8.85254C8.64489 9.15321 8.63211 9.62791 8.33144 9.91282C8.03077 10.1977 7.55607 10.1849 7.27117 9.88428L6.75021 9.33451V11.8315C6.75021 12.2458 6.41443 12.5815 6.00021 12.5815C5.586 12.5815 5.25021 12.2458 5.25021 11.8315V9.33451L4.72926 9.88428C4.44435 10.1849 3.96965 10.1977 3.66899 9.91282C3.36832 9.62791 3.35554 9.15321 3.64045 8.85254L5.45581 6.93677C5.59744 6.7873 5.7943 6.70264 6.00021 6.70264Z"
        fill="white"
      />
    </svg>
  ) : (
    <svg
      width="13"
      height="16"
      viewBox="0 0 13 16"
      fill="none"
      style={{marginRight: '8px'}}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.04004 2.35742V13.9942H10.9135V6.18897H8.0328C7.61858 6.18897 7.2828 5.85318 7.2828 5.43897V2.35742H2.04004ZM8.7828 3.4893L9.91959 4.68897H8.7828V3.4893ZM0.897549 1.25187C1.13197 1.00448 1.45881 0.857422 1.80871 0.857422H8.0328C8.23871 0.857422 8.43557 0.942083 8.5772 1.09155L12.2079 4.9231C12.3399 5.06242 12.4135 5.24704 12.4135 5.43897V14.1968C12.4135 14.5282 12.2891 14.8537 12.056 15.0997C11.8216 15.3471 11.4947 15.4942 11.1448 15.4942H1.80871C1.45881 15.4942 1.13197 15.3471 0.897549 15.0997C0.664426 14.8537 0.540039 14.5282 0.540039 14.1968V2.15479C0.540039 1.82334 0.664426 1.49789 0.897549 1.25187ZM6.47678 6.87843C6.68269 6.87843 6.87955 6.96309 7.02118 7.11256L8.83654 9.02833C9.12145 9.329 9.10867 9.8037 8.808 10.0886C8.50734 10.3735 8.03264 10.3607 7.74773 10.0601L7.22678 9.5103V12.0073C7.22678 12.4216 6.89099 12.7573 6.47678 12.7573C6.06256 12.7573 5.72678 12.4216 5.72678 12.0073V9.5103L5.20582 10.0601C4.92092 10.3607 4.44621 10.3735 4.14555 10.0886C3.84488 9.8037 3.83211 9.329 4.11701 9.02833L5.93237 7.11256C6.074 6.96309 6.27086 6.87843 6.47678 6.87843Z"
        fill="#0D0C07"
      />
    </svg>
  );
};
