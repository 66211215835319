export const DeclineIcon = () => {
  return (
    <svg width="23" height="28" viewBox="0 0 23 28" fill="none">
      <g clipPath="url(#clip0_1602_8643)">
        <path
          d="M20.1364 27.5H3.2593C3.09266 27.4522 2.92461 27.4114 2.76078 27.3566C1.49094 26.934 0.734375 25.8462 0.734375 24.4365C0.734375 17.4791 0.734375 10.5209 0.734375 3.56352C0.734375 3.47563 0.734375 3.38773 0.74 3.29984C0.801172 2.32602 1.2125 1.53148 2.03445 1.00695C2.40148 0.772813 2.84867 0.665234 3.2593 0.5C7.26781 0.5 11.2756 0.5 15.2841 0.5C15.4824 0.653984 15.699 0.789687 15.8755 0.965469C18.0109 3.09172 20.1364 5.22641 22.2739 7.35055C22.5502 7.62547 22.667 7.92219 22.6663 8.30961C22.6592 13.659 22.6613 19.0084 22.6613 24.3577C22.6613 24.4716 22.6641 24.5863 22.6557 24.7002C22.565 25.9011 21.9772 26.7631 20.8817 27.2645C20.6469 27.372 20.386 27.4227 20.1371 27.5H20.1364ZM14.292 2.08203H13.9911C10.5985 2.08203 7.20523 2.08203 3.81266 2.08203C2.84727 2.08203 2.31711 2.615 2.31711 3.58531C2.31711 10.528 2.31711 17.4713 2.31711 24.414C2.31711 25.3836 2.84867 25.9173 3.81266 25.9173C9.06852 25.9173 14.3251 25.9173 19.5809 25.9173C20.5442 25.9173 21.0786 25.3822 21.0786 24.4147C21.0786 19.3346 21.0786 14.2552 21.0786 9.17516C21.0786 9.08094 21.0786 8.98672 21.0786 8.86086C20.945 8.86086 20.8416 8.86086 20.739 8.86086C19.6048 8.86086 18.4714 8.86648 17.3373 8.85945C15.5323 8.84891 14.2955 7.60297 14.2913 5.79945C14.2892 4.98242 14.2913 4.16469 14.2913 3.34766C14.2913 2.93633 14.2913 2.525 14.2913 2.08062L14.292 2.08203ZM15.8741 3.26398C15.8741 3.3343 15.8741 3.39336 15.8741 3.45172C15.8741 4.26031 15.8698 5.0682 15.8755 5.8768C15.8804 6.69875 16.4162 7.25984 17.236 7.27531C18.0882 7.29148 18.9411 7.27883 19.7933 7.27742C19.8249 7.27742 19.8566 7.26125 19.8692 7.25773C18.5459 5.93516 17.2241 4.61398 15.8741 3.26398Z"
          fill="black"
        />
        <path
          d="M18.4169 17.4096C18.393 21.132 15.3639 24.1421 11.6711 24.1153C7.95652 24.0879 4.94574 21.0469 4.97879 17.3562C5.01254 13.6282 8.02543 10.6491 11.7379 10.673C15.442 10.6969 18.4408 13.721 18.4169 17.4103V17.4096ZM16.8349 17.3738C16.8236 14.5268 14.5167 12.2403 11.669 12.2543C8.83753 12.2684 6.54886 14.576 6.56082 17.4054C6.57277 20.2355 8.87832 22.5354 11.7007 22.5333C14.535 22.5312 16.8461 20.2088 16.8349 17.3738Z"
          fill="black"
        />
        <path
          d="M11.7009 18.49C11.2889 18.9077 10.9148 19.2909 10.5359 19.6692C10.1611 20.0439 9.67383 20.0721 9.34406 19.7444C9.01219 19.4146 9.03891 18.933 9.41508 18.5547C9.79266 18.175 10.1745 17.7989 10.5795 17.396C10.474 17.2877 10.3791 17.19 10.2834 17.0936C9.97336 16.7828 9.65695 16.477 9.3532 16.1599C9.02906 15.8224 9.03328 15.3344 9.35039 15.0257C9.66328 14.7213 10.133 14.7185 10.4641 15.0419C10.872 15.4399 11.2636 15.8547 11.689 16.2893C12.1095 15.866 12.5039 15.4694 12.8977 15.0721C13.0945 14.8732 13.328 14.7649 13.6099 14.8127C13.9193 14.8647 14.138 15.0377 14.2406 15.3428C14.3433 15.648 14.273 15.9201 14.0571 16.1458C13.7168 16.5009 13.3638 16.844 13.0165 17.1928C12.9553 17.2539 12.8977 17.3186 12.821 17.4002C13.2239 17.8003 13.6155 18.1891 14.0072 18.5779C14.2357 18.8043 14.344 19.0701 14.2547 19.39C14.0895 19.9835 13.3863 20.1719 12.9314 19.7346C12.5208 19.3394 12.1298 18.9246 11.7016 18.4893L11.7009 18.49Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_1602_8643">
          <rect
            width="21.9319"
            height="27"
            fill="white"
            transform="translate(0.734375 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
