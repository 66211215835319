export const ClientTypeIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 2.06836C5.80891 2.06836 4.0476 3.82178 4.0476 5.96645C4.0476 8.09135 5.77659 9.83218 7.93951 9.8641C7.95967 9.86396 7.97983 9.8639 8 9.8639C8.02017 9.8639 8.04033 9.86396 8.06049 9.8641C10.2234 9.83218 11.9524 8.09135 11.9524 5.96645C11.9524 3.82178 10.1911 2.06836 8 2.06836ZM11.0975 10.4092C12.5187 9.43651 13.4524 7.81219 13.4524 5.96645C13.4524 2.97698 11.003 0.568359 8 0.568359C4.99696 0.568359 2.5476 2.97698 2.5476 5.96645C2.5476 7.81219 3.48129 9.43651 4.90252 10.4092C4.42358 10.5834 3.95871 10.7983 3.5133 11.0525C2.14859 11.8314 1.01428 12.9523 0.22527 14.3034C0.0163898 14.6611 0.137023 15.1204 0.494713 15.3293C0.852402 15.5382 1.3117 15.4175 1.52058 15.0598C2.17599 13.9375 3.11941 13.0044 4.25684 12.3552C5.37569 11.7167 6.64306 11.3752 7.93538 11.3642C7.95689 11.3644 7.97843 11.3645 8 11.3645C8.02157 11.3645 8.04311 11.3644 8.06462 11.3642C9.35694 11.3752 10.6243 11.7167 11.7432 12.3552C12.8806 13.0044 13.824 13.9375 14.4794 15.0598C14.6883 15.4175 15.1476 15.5382 15.5053 15.3293C15.863 15.1204 15.9836 14.6611 15.7747 14.3034C14.9857 12.9523 13.8514 11.8314 12.4867 11.0525C12.0413 10.7983 11.5764 10.5834 11.0975 10.4092Z"
        fill="black"
      />
    </svg>
  );
};
