export const OffersIcon = () => {
  return (
    <svg width="32" height="33" viewBox="0 0 32 33" fill="none">
      <rect y="0.297852" width="32" height="32" rx="4" fill="#125C4F" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2409 10.0766V10.7979H16.5951C16.8082 10.7979 17.0127 10.8817 17.1645 11.0312L20.14 13.9616C20.2949 14.1142 20.3821 14.3225 20.3821 14.5398V20.1752H21.1395V12.5353L18.643 10.0766H13.2409ZM20.3821 21.7982H21.3559C21.7249 21.7982 22.0814 21.6539 22.3461 21.3932C22.6112 21.1321 22.7625 20.7753 22.7625 20.4006V12.1955C22.7625 11.9781 22.6752 11.7699 22.5204 11.6173L19.5449 8.68693C19.3931 8.53742 19.1886 8.45361 18.9755 8.45361H13.0245C12.6554 8.45361 12.299 8.5979 12.0343 8.85859C11.7692 9.1197 11.6179 9.47649 11.6179 9.85119V10.7979H10.6441C10.2751 10.7979 9.91863 10.9422 9.65393 11.2029C9.3888 11.464 9.23755 11.8208 9.23755 12.1955V22.7449C9.23755 23.1196 9.3888 23.4764 9.65393 23.7375C9.91863 23.9982 10.2751 24.1425 10.6441 24.1425H18.9755C19.3446 24.1425 19.701 23.9982 19.9657 23.7375C20.2308 23.4764 20.3821 23.1196 20.3821 22.7449V21.7982ZM18.7591 14.8796L16.2626 12.4209H10.8605V22.5195H18.7591V14.8796ZM12.213 18.0563C12.213 17.6081 12.5763 17.2448 13.0245 17.2448H16.5951C17.0433 17.2448 17.4066 17.6081 17.4066 18.0563C17.4066 18.5045 17.0433 18.8678 16.5951 18.8678H13.0245C12.5763 18.8678 12.213 18.5045 12.213 18.0563ZM12.213 20.4006C12.213 19.9524 12.5763 19.5891 13.0245 19.5891H16.5951C17.0433 19.5891 17.4066 19.9524 17.4066 20.4006C17.4066 20.8488 17.0433 21.2121 16.5951 21.2121H13.0245C12.5763 21.2121 12.213 20.8488 12.213 20.4006Z"
        fill="white"
      />
    </svg>
  );
};
