export const DescriptionIcon = () => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.5 5C0.5 4.44772 0.947715 4 1.5 4H22.5C23.0523 4 23.5 4.44772 23.5 5C23.5 5.55228 23.0523 6 22.5 6H1.5C0.947715 6 0.5 5.55228 0.5 5ZM0.5 10C0.5 9.44772 0.947715 9 1.5 9H16.7727C17.325 9 17.7727 9.44772 17.7727 10C17.7727 10.5523 17.325 11 16.7727 11H1.5C0.947715 11 0.5 10.5523 0.5 10ZM0.5 15C0.5 14.4477 0.947715 14 1.5 14H22.5C23.0523 14 23.5 14.4477 23.5 15C23.5 15.5523 23.0523 16 22.5 16H1.5C0.947715 16 0.5 15.5523 0.5 15ZM0.5 20C0.5 19.4477 0.947715 19 1.5 19H16.7727C17.325 19 17.7727 19.4477 17.7727 20C17.7727 20.5523 17.325 21 16.7727 21H1.5C0.947715 21 0.5 20.5523 0.5 20Z"
        fill="#262626"
      />
    </svg>
  );
};
