export const ErrorIcon = () => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none">
      <path
        d="M7.62499 9.28957H9.37499V4.35157H7.62499V9.28957ZM8.49999 12.6856C8.73599 12.6856 8.94099 12.6056 9.11499 12.4456C9.28833 12.2856 9.37499 12.0736 9.37499 11.8096C9.37499 11.5736 9.28833 11.3689 9.11499 11.1956C8.94099 11.0222 8.73599 10.9356 8.49999 10.9356C8.26399 10.9356 8.05899 11.0222 7.88499 11.1956C7.71166 11.3689 7.62499 11.5736 7.62499 11.8096C7.62499 12.0736 7.71166 12.2856 7.88499 12.4456C8.05899 12.6056 8.26399 12.6856 8.49999 12.6856ZM8.49999 16.8306C7.34733 16.8306 6.26399 16.6119 5.24999 16.1746C4.23599 15.7372 3.35399 15.1436 2.60399 14.3936C1.85399 13.6436 1.26033 12.7616 0.822992 11.7476C0.385659 10.7336 0.166992 9.65023 0.166992 8.49757C0.166992 7.3449 0.385659 6.26157 0.822992 5.24757C1.26033 4.23357 1.85399 3.35157 2.60399 2.60157C3.35399 1.85157 4.23599 1.2579 5.24999 0.820566C6.26399 0.383233 7.34733 0.164566 8.49999 0.164566C9.65266 0.164566 10.736 0.383233 11.75 0.820566C12.764 1.2579 13.646 1.85157 14.396 2.60157C15.146 3.35157 15.7397 4.23357 16.177 5.24757C16.6143 6.26157 16.833 7.3449 16.833 8.49757C16.833 9.65023 16.6143 10.7336 16.177 11.7476C15.7397 12.7616 15.146 13.6436 14.396 14.3936C13.646 15.1436 12.764 15.7372 11.75 16.1746C10.736 16.6119 9.65266 16.8306 8.49999 16.8306ZM8.49999 15.0806C10.3193 15.0806 11.8713 14.4382 13.156 13.1536C14.4407 11.8689 15.083 10.3169 15.083 8.49757C15.083 6.67823 14.4407 5.12623 13.156 3.84157C11.8713 2.5569 10.3193 1.91457 8.49999 1.91457C6.68066 1.91457 5.12866 2.5569 3.84399 3.84157C2.55933 5.12623 1.91699 6.67823 1.91699 8.49757C1.91699 10.3169 2.55933 11.8689 3.84399 13.1536C5.12866 14.4382 6.68066 15.0806 8.49999 15.0806Z"
        fill="#FB6F3F"
      />
    </svg>
  );
};
