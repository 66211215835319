export const ClientIcon = () => {
  return (
    <svg width="32" height="33" viewBox="0 0 32 33" fill="none">
      <rect y="0.297852" width="32" height="32" rx="4" fill="#125C4F" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 10.3662C13.8089 10.3662 12.0476 12.1196 12.0476 14.2643C12.0476 16.3892 13.7766 18.13 15.9395 18.1619C15.9597 18.1618 15.9798 18.1617 16 18.1617C16.0202 18.1617 16.0403 18.1618 16.0605 18.1619C18.2234 18.13 19.9524 16.3892 19.9524 14.2643C19.9524 12.1196 18.1911 10.3662 16 10.3662ZM19.0975 18.7071C20.5187 17.7344 21.4524 16.11 21.4524 14.2643C21.4524 11.2748 19.003 8.86621 16 8.86621C12.997 8.86621 10.5476 11.2748 10.5476 14.2643C10.5476 16.11 11.4813 17.7344 12.9025 18.7071C12.4236 18.8812 11.9587 19.0961 11.5133 19.3503C10.1486 20.1292 9.01428 21.2502 8.22527 22.6013C8.01639 22.959 8.13702 23.4183 8.49471 23.6271C8.8524 23.836 9.3117 23.7154 9.52058 23.3577C10.176 22.2354 11.1194 21.3023 12.2568 20.6531C13.3757 20.0145 14.6431 19.673 15.9354 19.662C15.9569 19.6623 15.9784 19.6624 16 19.6624C16.0216 19.6624 16.0431 19.6623 16.0646 19.662C17.3569 19.673 18.6243 20.0145 19.7432 20.6531C20.8806 21.3023 21.824 22.2354 22.4794 23.3577C22.6883 23.7154 23.1476 23.836 23.5053 23.6271C23.863 23.4183 23.9836 22.959 23.7747 22.6013C22.9857 21.2502 21.8514 20.1292 20.4867 19.3503C20.0413 19.0961 19.5764 18.8812 19.0975 18.7071Z"
        fill="white"
      />
    </svg>
  );
};
