import {FC} from 'react';

type TProps = {
  size: string;
};

export const LinkedInIcon: FC<TProps> = ({size = '50'}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 50 50" fill="none">
      <g filter="url(#filter0_d_1178_31508)">
        <rect x="9" y="3.03345" width="32" height="32" rx="16" fill="#157EE7" />
      </g>
      <path
        d="M16.7756 13.0215C16.7756 12.494 16.9609 12.0589 17.3313 11.716C17.7018 11.3732 18.1834 11.2018 18.7761 11.2018C19.3583 11.2018 19.8293 11.3706 20.1892 11.7081C20.5597 12.0563 20.7449 12.5099 20.7449 13.069C20.7449 13.5753 20.565 13.9973 20.2051 14.3348C19.8346 14.683 19.3477 14.857 18.7444 14.857H18.7285C18.1463 14.857 17.6753 14.683 17.3155 14.3348C16.9556 13.9867 16.7756 13.5489 16.7756 13.0215ZM16.982 26.8671V16.297H20.5067V26.8671H16.982ZM22.4596 26.8671H25.9843V20.9649C25.9843 20.5957 26.0266 20.3109 26.1113 20.1104C26.2595 19.7518 26.4844 19.4485 26.7861 19.2006C27.0878 18.9527 27.4662 18.8287 27.9213 18.8287C29.1068 18.8287 29.6995 19.6252 29.6995 21.2181V26.8671H33.2242V20.8067C33.2242 19.2454 32.8538 18.0613 32.1128 17.2543C31.3719 16.4473 30.3928 16.0438 29.1756 16.0438C27.8102 16.0438 26.7464 16.6293 25.9843 17.8002V17.8319H25.9684L25.9843 17.8002V16.297H22.4596C22.4808 16.6345 22.4914 17.6842 22.4914 19.4459C22.4914 21.2075 22.4808 23.6813 22.4596 26.8671Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_d_1178_31508"
          x="0.56613"
          y="0.7333"
          width="48.8677"
          height="48.8677"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="6.13372" />
          <feGaussianBlur stdDeviation="4.21694" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0823529 0 0 0 0 0.494118 0 0 0 0 0.905882 0 0 0 0.34 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1178_31508"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1178_31508"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
