export const EmailIcon = () => {
  return (
    <svg width="21" height="16" viewBox="0 0 21 16" fill="none">
      <path
        d="M1.25 0.912109H19.6711V14.9121H1.25V0.912109Z"
        stroke="#125C4F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.98682 1.64893L10.4605 9.01735L18.9342 1.64893"
        stroke="#125C4F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
