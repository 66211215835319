import {FC} from 'react';

type TProps = {
  small?: boolean;
  big?: boolean;
  notes?: boolean;
};

export const DeleteIcon: FC<TProps> = ({small = false, big, notes}) => {
  return notes ? (
    <svg width="14" height="16" viewBox="0 0 14 16" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.06377 1.28513C4.4094 0.941894 4.87708 0.75 5.36364 0.75H8.63636C9.12292 0.75 9.5906 0.941894 9.93623 1.28513C10.282 1.62853 10.4773 2.09541 10.4773 2.58333V2.91667H13C13.4142 2.91667 13.75 3.25245 13.75 3.66667C13.75 4.08088 13.4142 4.41667 13 4.41667H12.6591V13.9583C12.6591 14.3026 12.5213 14.6317 12.2778 14.8735C12.0345 15.1152 11.7055 15.25 11.3636 15.25H2.63636C2.29447 15.25 1.96553 15.1152 1.72219 14.8735C1.47867 14.6317 1.34091 14.3026 1.34091 13.9583V4.41667H1C0.585786 4.41667 0.25 4.08088 0.25 3.66667C0.25 3.25245 0.585786 2.91667 1 2.91667H3.52273V2.58333C3.52273 2.09541 3.71796 1.62853 4.06377 1.28513ZM2.84091 4.41667V13.75H11.1591V4.41667H2.84091ZM8.97727 2.91667H5.02273V2.58333C5.02273 2.49662 5.05737 2.4124 5.12073 2.34948C5.18426 2.28638 5.27154 2.25 5.36364 2.25H8.63636C8.72846 2.25 8.81574 2.28638 8.87927 2.34948C8.94264 2.4124 8.97727 2.49662 8.97727 2.58333V2.91667ZM5.36364 6.16667C5.77785 6.16667 6.11364 6.50245 6.11364 6.91667V11.25C6.11364 11.6642 5.77785 12 5.36364 12C4.94942 12 4.61364 11.6642 4.61364 11.25V6.91667C4.61364 6.50245 4.94942 6.16667 5.36364 6.16667ZM8.63636 6.16667C9.05058 6.16667 9.38636 6.50245 9.38636 6.91667V11.25C9.38636 11.6642 9.05058 12 8.63636 12C8.22215 12 7.88636 11.6642 7.88636 11.25V6.91667C7.88636 6.50245 8.22215 6.16667 8.63636 6.16667Z"
        fill="#0D0C07"
      />
    </svg>
  ) : big ? (
    <svg width="20" height="22" viewBox="0 0 20 22" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.65003 1.27501C6.16208 0.777914 6.85493 0.499999 7.57576 0.499999L12.4242 0.499998C13.1451 0.499998 13.8379 0.777912 14.35 1.27501C14.8623 1.77236 15.1515 2.44852 15.1515 3.15517L15.1515 3.63793L18.8889 3.63793C19.5025 3.63793 20 4.12424 20 4.72413C20 5.32403 19.5025 5.81034 18.8889 5.81034L18.3838 5.81034L18.3838 19.6293C18.3838 20.1279 18.1798 20.6045 17.819 20.9548C17.4585 21.3047 16.9712 21.5 16.4647 21.5L3.53536 21.5C3.02885 21.5 2.54153 21.3047 2.18103 20.9548C1.82026 20.6045 1.61617 20.1279 1.61616 19.6293L1.61616 5.81034L1.11111 5.81034C0.497462 5.81034 8.4346e-07 5.32403 7.38571e-07 4.72414C6.33682e-07 4.12424 0.497462 3.63793 1.11111 3.63793L4.84849 3.63793L4.84849 3.15517C4.84849 2.44852 5.13772 1.77236 5.65003 1.27501ZM3.83838 5.81034L3.83839 19.3276L16.1616 19.3276L16.1616 5.81034L3.83838 5.81034ZM12.9293 3.63793L7.07071 3.63793L7.07071 3.15517C7.07071 3.02959 7.12202 2.90761 7.21589 2.81648C7.31002 2.7251 7.43932 2.67241 7.57576 2.67241L12.4242 2.67241C12.5607 2.67241 12.69 2.7251 12.7841 2.81648C12.878 2.90761 12.9293 3.02959 12.9293 3.15517L12.9293 3.63793ZM7.57576 8.34483C8.18941 8.34483 8.68687 8.83114 8.68687 9.43103L8.68687 15.7069C8.68687 16.3068 8.18941 16.7931 7.57576 16.7931C6.96211 16.7931 6.46465 16.3068 6.46465 15.7069L6.46465 9.43103C6.46465 8.83114 6.96211 8.34483 7.57576 8.34483ZM12.4242 8.34483C13.0379 8.34483 13.5354 8.83114 13.5354 9.43103L13.5354 15.7069C13.5354 16.3068 13.0379 16.7931 12.4242 16.7931C11.8106 16.7931 11.3131 16.3068 11.3131 15.7069L11.3131 9.43103C11.3131 8.83114 11.8106 8.34483 12.4242 8.34483Z"
        fill="#625A28"
      />
    </svg>
  ) : small ? (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.31377 5.28513C9.6594 4.94189 10.1271 4.75 10.6136 4.75H13.8864C14.3729 4.75 14.8406 4.94189 15.1862 5.28513C15.532 5.62853 15.7273 6.09541 15.7273 6.58333V6.91667H18.25C18.6642 6.91667 19 7.25245 19 7.66667C19 8.08088 18.6642 8.41667 18.25 8.41667H17.9091V17.9583C17.9091 18.3026 17.7713 18.6317 17.5278 18.8735C17.2845 19.1152 16.9555 19.25 16.6136 19.25H7.88636C7.54447 19.25 7.21553 19.1152 6.97219 18.8735C6.72867 18.6317 6.59091 18.3026 6.59091 17.9583V8.41667H6.25C5.83579 8.41667 5.5 8.08088 5.5 7.66667C5.5 7.25245 5.83579 6.91667 6.25 6.91667H8.77273V6.58333C8.77273 6.09541 8.96796 5.62853 9.31377 5.28513ZM8.09091 8.41667V17.75H16.4091V8.41667H8.09091ZM14.2273 6.91667H10.2727V6.58333C10.2727 6.49662 10.3074 6.4124 10.3707 6.34948C10.4343 6.28638 10.5215 6.25 10.6136 6.25H13.8864C13.9785 6.25 14.0657 6.28638 14.1293 6.34948C14.1926 6.4124 14.2273 6.49662 14.2273 6.58333V6.91667ZM10.6136 10.1667C11.0279 10.1667 11.3636 10.5025 11.3636 10.9167V15.25C11.3636 15.6642 11.0279 16 10.6136 16C10.1994 16 9.86364 15.6642 9.86364 15.25V10.9167C9.86364 10.5025 10.1994 10.1667 10.6136 10.1667ZM13.8864 10.1667C14.3006 10.1667 14.6364 10.5025 14.6364 10.9167V15.25C14.6364 15.6642 14.3006 16 13.8864 16C13.4722 16 13.1364 15.6642 13.1364 15.25V10.9167C13.1364 10.5025 13.4722 10.1667 13.8864 10.1667Z"
        fill="#262626"
      />
    </svg>
  ) : (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none">
      <path
        d="M14.6184 4.03638H0.868408M5.86841 7.47388V12.4739M9.61841 7.47388V12.4739M13.3684 4.03638V15.5989C13.3684 15.7646 13.3026 15.9236 13.1854 16.0408C13.0681 16.158 12.9092 16.2239 12.7434 16.2239H2.74341C2.57765 16.2239 2.41868 16.158 2.30147 16.0408C2.18426 15.9236 2.11841 15.7646 2.11841 15.5989V4.03638M10.8684 4.03638V2.16138C10.8684 1.82986 10.7367 1.51191 10.5023 1.27749C10.2679 1.04307 9.94993 0.911377 9.61841 0.911377H5.86841C5.53689 0.911377 5.21895 1.04307 4.98452 1.27749C4.7501 1.51191 4.61841 1.82986 4.61841 2.16138V4.03638"
        stroke="#0D0C07"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
