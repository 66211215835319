import {FC} from 'react';

type TProps = {
  bold?: boolean;
};

export const TickIcon: FC<TProps> = ({bold}) => {
  return bold ? (
    <svg
      width="10"
      height="8"
      viewBox="0 0 10 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.72318 0.237414C10.0707 0.574491 10.0942 1.14584 9.77561 1.51356L4.40976 7.70711C4.25243 7.88871 4.03181 7.99443 3.79903 7.99979C3.56625 8.00514 3.3415 7.90965 3.17686 7.73545L0.250031 4.63868C-0.0833435 4.28595 -0.0833435 3.71406 0.250031 3.36133C0.583405 3.00859 1.12391 3.00859 1.45729 3.36133L3.75367 5.79105L8.51706 0.292899C8.83564 -0.0748211 9.37564 -0.0996624 9.72318 0.237414Z"
        fill="white"
      />
    </svg>
  ) : (
    <svg width="25" height="23" viewBox="0 0 25 23" fill="none">
      <path
        d="M23.5381 2.6964L11.4876 14.5136L6.87773 9.63606C6.71598 9.47744 6.47335 9.47744 6.3116 9.5964C6.14985 9.71537 6.14985 9.99295 6.27117 10.1516L11.2046 15.3464C11.2854 15.4257 11.3663 15.4654 11.4876 15.4654C11.6089 15.4654 11.6898 15.4257 11.7707 15.3464L24.1042 3.25157C24.266 3.09295 24.2255 2.81537 24.0638 2.6964C23.902 2.57744 23.6998 2.57744 23.5381 2.6964Z"
        fill="black"
      />
      <path
        d="M4.97696 23H19.5345C22.0012 23 23.9827 21.0569 23.9827 18.6379V10.7466C23.9827 10.5086 23.8209 10.35 23.5783 10.35C23.3357 10.35 23.1739 10.5086 23.1739 10.7466V18.6379C23.1739 20.6207 21.5564 22.2069 19.5345 22.2069H4.97696C2.95507 22.2069 1.33756 20.6207 1.33756 18.6379V4.36207C1.33756 2.37931 2.95507 0.793103 4.97696 0.793103H18.0383C18.281 0.793103 18.4427 0.634483 18.4427 0.396552C18.4427 0.158621 18.281 0 18.0383 0H4.97696C2.51026 0 0.528809 1.9431 0.528809 4.36207V18.6379C0.528809 21.0569 2.51026 23 4.97696 23Z"
        fill="black"
      />
    </svg>
  );
};
