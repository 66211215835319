import {FC} from 'react';

type TProps = {
  width?: number;
  height?: number;
  small?: boolean;
  big?: boolean;
};

export const LogoIcon: FC<TProps> = ({small, big}) => {
  return big ? (
    <svg
      width="252"
      height="48"
      viewBox="0 0 252 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M26.7828 48C41.7542 48 53.1257 38.5143 53.1257 24C53.1257 9.54286 41.7542 0 26.7828 0C11.6971 0 0.382812 9.54286 0.382812 24C0.382812 38.5143 11.6971 48 26.7828 48ZM26.7828 46.9143C15.1828 46.9143 8.83996 36.0571 8.83996 24C8.83996 12 15.1828 1.08571 26.7828 1.08571C38.2685 1.08571 44.6685 12 44.6685 24C44.6685 36.0571 38.2685 46.9143 26.7828 46.9143Z"
        fill="black"
      />
      <path
        d="M87.2065 0C82.9779 0 80.0636 1.2 78.3493 4.97143C76.5208 3.54286 74.4065 2.28572 71.3208 2.28572C65.4351 2.28572 60.6922 5.65715 60.6922 17.4857V19.5429H55.2065V20.6286H60.6922V35.0857C60.6922 38.8571 60.6922 41.3143 60.1208 46.8571H68.0065C67.4922 41.3143 67.4922 38.8571 67.4922 35.0857V20.6286H76.6922V35.0857C76.6922 38.8571 76.6922 41.3143 76.1208 46.8571H84.0065C83.4922 41.3143 83.4922 38.8571 83.4922 35.0857V20.6286H91.8922V19.5429H83.4922L83.4351 16.7429C79.8922 5.94286 81.2065 1.08571 86.0636 1.08571C89.6636 1.08571 91.0351 4.28571 93.5493 5.6C96.3493 7.02857 98.0636 5.2 98.0636 2.8C94.5208 1.31429 91.2065 0 87.2065 0ZM76.6922 19.5429H67.4922L67.4351 17.7143C64.4065 7.88572 67.2636 3.37143 71.8351 3.37143C74.2351 3.37143 76.1779 4.62857 77.9493 6.11429C77.0922 8.57143 76.6922 11.9429 76.6922 16.5143V19.5429Z"
        fill="black"
      />
      <path
        d="M106.593 48C114.536 48 119.05 43.4857 121.107 36.4H120.136C118.136 42.8571 113.964 46.1714 108.707 46.1714C101.45 46.1714 97.7926 39.9429 97.7926 32.6857C97.7926 31.7714 97.8498 30.8571 98.0212 30H119.678C119.621 23.8857 114.65 18.4 106.021 18.4C97.8498 18.4 90.3641 23.8286 90.3641 33.2C90.3641 42.6286 97.6784 48 106.593 48ZM98.0784 28.9143C98.9355 22.6857 101.793 19.4857 105.907 19.4857C110.478 19.4857 112.478 23.4286 112.478 28.9143H98.0784Z"
        fill="black"
      />
      <path
        d="M146.739 19.5429C139.425 18.2286 135.882 22.2857 131.539 27.3143C131.596 24.9714 131.711 22.6286 132.053 19.5429H124.168C124.739 24.1714 124.739 27.0286 124.739 30.8V35.6571C124.739 39.4286 124.739 42.2286 124.168 46.8571H132.053C131.539 42.2286 131.539 39.4286 131.539 35.6571V30.8V28.9143C137.025 23.0286 139.082 22.7429 142.053 23.7143C145.539 24.9143 147.425 22.7429 146.739 19.5429Z"
        fill="black"
      />
      <path
        d="M194.137 19.5429C191.623 19.2571 189.337 20.2286 190.194 22.4571C190.88 24.3429 193.68 25.7714 191.966 29.6571L185.223 45.1429L176.137 24.2857C175.337 22.4 174.88 21.2571 174.594 19.5429H165.966C167.109 21.3143 167.852 22.5143 168.652 24.4L172.823 34.0571L168.023 45.1429L158.937 24.2857C158.08 22.4 157.68 21.2571 157.337 19.5429H148.766C149.909 21.3143 150.594 22.5143 151.452 24.4L161.109 46.8571H169.223C169.052 45.4286 169.509 44.4571 170.252 42.6857L173.394 35.4286L178.309 46.8571H186.48C186.309 45.4286 186.709 44.4571 187.452 42.6857L193.794 28.2857C195.223 24.9714 194.937 21.8857 194.137 19.5429Z"
        fill="black"
      />
      <path
        d="M212.631 48C220.573 48 225.088 43.4857 227.145 36.4H226.173C224.173 42.8571 220.002 46.1714 214.745 46.1714C207.488 46.1714 203.831 39.9429 203.831 32.6857C203.831 31.7714 203.888 30.8571 204.059 30H225.716C225.659 23.8857 220.688 18.4 212.059 18.4C203.888 18.4 196.402 23.8286 196.402 33.2C196.402 42.6286 203.716 48 212.631 48ZM204.116 28.9143C204.973 22.6857 207.831 19.4857 211.945 19.4857C216.516 19.4857 218.516 23.4286 218.516 28.9143H204.116Z"
        fill="black"
      />
      <path
        d="M238.426 46.8571C237.912 41.9429 237.912 38.8571 237.912 35.0857V12.9143C237.912 9.14286 237.912 6.05714 238.426 1.14286H230.54C231.112 6.05714 231.112 9.14286 231.112 12.9143V35.0857C231.112 38.8571 231.112 41.9429 230.54 46.8571H238.426Z"
        fill="black"
      />
      <path
        d="M251.618 46.8571C251.104 41.9429 251.104 38.8571 251.104 35.0857V12.9143C251.104 9.14286 251.104 6.05714 251.618 1.14286H243.732C244.304 6.05714 244.304 9.14286 244.304 12.9143V35.0857C244.304 38.8571 244.304 41.9429 243.732 46.8571H251.618Z"
        fill="black"
      />
    </svg>
  ) : small ? (
    <svg width="56" height="24" viewBox="0 0 56 24" fill="none">
      <path
        d="M13.9457 24C21.968 24 27.8914 19.4595 27.8914 12C27.8914 4.54054 21.968 0 13.9457 0C5.87678 0 0 4.54054 0 12C0 19.4595 5.87678 24 13.9457 24ZM13.9457 23.1197C8.72189 23.1197 6.06335 19.0425 6.06335 12C6.06335 5.00386 8.72189 0.880309 13.9457 0.880309C19.1229 0.880309 21.7814 5.00386 21.7814 12C21.7814 19.0425 19.1229 23.1197 13.9457 23.1197Z"
        fill="black"
      />
      <path
        d="M54.8423 0.926642C52.7901 0.694982 50.9244 1.48263 51.6241 3.28958C52.1838 4.81853 54.4692 5.97683 53.0699 9.12741L47.5663 21.6834L40.1503 4.7722C39.4974 3.24324 39.1242 2.3166 38.891 0.926642H31.8482C32.7811 2.36293 33.3874 3.33591 34.0404 4.86487L37.4452 12.695L33.5273 21.6834L27.0052 7.35349C26.3056 5.82453 27.285 8.74345 27.0052 7.35349L21.1751 4.7722C20.4754 3.24324 22.1079 6.20849 21.1751 4.7722L27.8837 23.0734H34.5068C34.3669 21.9151 34.74 21.1274 35.3463 19.6911L37.9116 13.807L41.9227 23.0734H48.5924C48.4525 21.9151 48.779 21.1274 49.3853 19.6911L54.5625 8.01544C55.7285 5.32819 55.4953 2.82626 54.8423 0.926642Z"
        fill="black"
      />
    </svg>
  ) : (
    <svg width="126" height="25" viewBox="0 0 126 25" fill="none">
      <path
        d="M13.45 24.5C20.9357 24.5 26.6214 19.7571 26.6214 12.5C26.6214 5.27143 20.9357 0.5 13.45 0.5C5.90714 0.5 0.25 5.27143 0.25 12.5C0.25 19.7571 5.90714 24.5 13.45 24.5ZM13.45 23.9571C7.65 23.9571 4.47857 18.5286 4.47857 12.5C4.47857 6.5 7.65 1.04286 13.45 1.04286C19.1929 1.04286 22.3929 6.5 22.3929 12.5C22.3929 18.5286 19.1929 23.9571 13.45 23.9571Z"
        fill="#0D0C07"
      />
      <path
        d="M43.6618 0.5C41.5476 0.5 40.0904 1.1 39.2333 2.98571C38.319 2.27143 37.2618 1.64286 35.719 1.64286C32.7761 1.64286 30.4047 3.32857 30.4047 9.24286V10.2714H27.6618V10.8143H30.4047V18.0429C30.4047 19.9286 30.4047 21.1571 30.119 23.9286H34.0618C33.8047 21.1571 33.8047 19.9286 33.8047 18.0429V10.8143H38.4047V18.0429C38.4047 19.9286 38.4047 21.1571 38.119 23.9286H42.0618C41.8047 21.1571 41.8047 19.9286 41.8047 18.0429V10.8143H46.0047V10.2714H41.8047L41.7761 8.87143C40.0047 3.47143 40.6618 1.04286 43.0904 1.04286C44.8904 1.04286 45.5761 2.64286 46.8333 3.3C48.2333 4.01429 49.0904 3.1 49.0904 1.9C47.319 1.15714 45.6618 0.5 43.6618 0.5ZM38.4047 10.2714H33.8047L33.7761 9.35714C32.2618 4.44286 33.6904 2.18571 35.9761 2.18571C37.1761 2.18571 38.1475 2.81429 39.0333 3.55714C38.6047 4.78572 38.4047 6.47143 38.4047 8.75714V10.2714Z"
        fill="#0D0C07"
      />
      <path
        d="M53.3549 24.5C57.3263 24.5 59.5835 22.2429 60.6121 18.7H60.1263C59.1263 21.9286 57.0406 23.5857 54.4121 23.5857C50.7835 23.5857 48.9549 20.4714 48.9549 16.8429C48.9549 16.3857 48.9835 15.9286 49.0692 15.5H59.8978C59.8692 12.4429 57.3835 9.7 53.0692 9.7C48.9835 9.7 45.2406 12.4143 45.2406 17.1C45.2406 21.8143 48.8978 24.5 53.3549 24.5ZM49.0978 14.9571C49.5263 11.8429 50.9549 10.2429 53.0121 10.2429C55.2978 10.2429 56.2978 12.2143 56.2978 14.9571H49.0978Z"
        fill="#0D0C07"
      />
      <path
        d="M73.4281 10.2714C69.771 9.61429 67.9996 11.6429 65.8281 14.1571C65.8567 12.9857 65.9138 11.8143 66.0853 10.2714H62.1424C62.4281 12.5857 62.4281 14.0143 62.4281 15.9V18.3286C62.4281 20.2143 62.4281 21.6143 62.1424 23.9286H66.0853C65.8281 21.6143 65.8281 20.2143 65.8281 18.3286V15.9V14.9571C68.571 12.0143 69.5996 11.8714 71.0853 12.3571C72.8281 12.9571 73.771 11.8714 73.4281 10.2714Z"
        fill="#0D0C07"
      />
      <path
        d="M97.1272 10.2714C95.8701 10.1286 94.7272 10.6143 95.1558 11.7286C95.4987 12.6714 96.8987 13.3857 96.0415 15.3286L92.6701 23.0714L88.1272 12.6429C87.7272 11.7 87.4987 11.1286 87.3558 10.2714H83.0415C83.6129 11.1571 83.9844 11.7571 84.3844 12.7L86.4701 17.5286L84.0701 23.0714L79.5272 12.6429C79.0987 11.7 78.8987 11.1286 78.7272 10.2714H74.4415C75.013 11.1571 75.3558 11.7571 75.7844 12.7L80.6129 23.9286H84.6701C84.5844 23.2143 84.813 22.7286 85.1844 21.8429L86.7558 18.2143L89.213 23.9286H93.2987C93.213 23.2143 93.4129 22.7286 93.7844 21.8429L96.9558 14.6429C97.6701 12.9857 97.5272 11.4429 97.1272 10.2714Z"
        fill="#0D0C07"
      />
      <path
        d="M106.374 24.5C110.345 24.5 112.602 22.2429 113.631 18.7H113.145C112.145 21.9286 110.06 23.5857 107.431 23.5857C103.802 23.5857 101.974 20.4714 101.974 16.8429C101.974 16.3857 102.002 15.9286 102.088 15.5H112.917C112.888 12.4429 110.402 9.7 106.088 9.7C102.002 9.7 98.2596 12.4143 98.2596 17.1C98.2596 21.8143 101.917 24.5 106.374 24.5ZM102.117 14.9571C102.545 11.8429 103.974 10.2429 106.031 10.2429C108.317 10.2429 109.317 12.2143 109.317 14.9571H102.117Z"
        fill="#0D0C07"
      />
      <path
        d="M119.272 23.9286C119.015 21.4714 119.015 19.9286 119.015 18.0429V6.95714C119.015 5.07143 119.015 3.52857 119.272 1.07143H115.329C115.615 3.52857 115.615 5.07143 115.615 6.95714V18.0429C115.615 19.9286 115.615 21.4714 115.329 23.9286H119.272Z"
        fill="#0D0C07"
      />
      <path
        d="M125.868 23.9286C125.611 21.4714 125.61 19.9286 125.61 18.0429V6.95714C125.61 5.07143 125.611 3.52857 125.868 1.07143H121.925C122.211 3.52857 122.211 5.07143 122.211 6.95714V18.0429C122.211 19.9286 122.211 21.4714 121.925 23.9286H125.868Z"
        fill="#0D0C07"
      />
    </svg>
  );
};
