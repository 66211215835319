export const FileIcon = () => {
  return (
    <svg
      width="17"
      height="22"
      viewBox="0 0 17 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.4523 2.34533H2.80351C2.54359 2.34533 2.35117 2.53775 2.35117 2.79767V11.1953C2.35117 11.3944 2.27209 11.5853 2.13133 11.7261C1.99057 11.8668 1.79965 11.9459 1.60059 11.9459C1.40152 11.9459 1.2106 11.8668 1.06984 11.7261C0.929079 11.5853 0.85 11.3944 0.85 11.1953V2.79767C0.85 1.72738 1.73448 0.850018 2.80351 0.850018H11.2006H11.2012V1.00002C11.3602 0.998803 11.5132 1.06076 11.6266 1.17228L10.4523 2.34533ZM10.4523 2.34533V5.19767C10.4523 6.26733 11.3327 7.14767 12.4023 7.14767H15.2547L10.4523 2.34533ZM11.9523 5.19767V3.40707L14.1929 5.64767H12.4023C12.1424 5.64767 11.9523 5.45762 11.9523 5.19767ZM1.60234 12.8477C1.18812 12.8477 0.852333 13.1835 0.852344 13.5977C0.852349 14.0119 1.18813 14.3477 1.60234 14.3477C2.01655 14.3477 2.35234 14.0119 2.35234 13.5977C2.35235 13.1835 2.01656 12.8477 1.60234 12.8477Z"
        fill="#625A28"
        stroke="#625A28"
        strokeWidth="0.3"
      />
      <line
        x1="8.63672"
        y1="17.874"
        x2="8.63672"
        y2="10.9998"
        stroke="#625A28"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g filter="url(#filter0_d_9481_35270)">
        <path
          d="M11.5109 12.2902L8.67969 9.45898L5.84846 12.2902"
          stroke="#625A28"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_9481_35270"
          x="0.847656"
          y="8.45898"
          width="15.6641"
          height="12.8311"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_9481_35270"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_9481_35270"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
