export const RefreshIcon = () => {
  return (
    <svg width="23" height="20" viewBox="0 0 23 20" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.73482 0.764037C8.96754 0.259768 10.2895 0 11.625 0C12.9604 0 14.2824 0.259768 15.5151 0.764037C16.7478 1.26829 17.8666 2.00693 18.8079 2.93693C19.2201 3.34425 19.2146 3.99922 18.7954 4.39986C18.3763 4.80049 17.7023 4.79507 17.29 4.38775C16.545 3.65164 15.661 3.06851 14.6891 2.67094C13.7173 2.27339 12.6761 2.06897 11.625 2.06897C10.5739 2.06897 9.53268 2.27339 8.56083 2.67094C7.58895 3.06851 6.70499 3.65164 5.95994 4.38775L5.9583 4.38937L4.23757 6.08198H6.64692C7.23484 6.08198 7.71144 6.54514 7.71144 7.11647C7.71144 7.6878 7.23484 8.15095 6.64692 8.15095H1.68952C1.1016 8.15095 0.625 7.6878 0.625 7.11647V2.22567C0.625 1.65434 1.1016 1.19118 1.68952 1.19118C2.27743 1.19118 2.75403 1.65434 2.75403 2.22567V4.59739L4.44208 2.93693C4.44237 2.93664 4.44266 2.93636 4.44294 2.93607C5.38405 2.00648 6.50251 1.26814 7.73482 0.764037ZM15.5386 12.8835C15.5386 12.3122 16.0152 11.8491 16.6031 11.8491H21.5605C22.1484 11.8491 22.625 12.3122 22.625 12.8835V17.7743C22.625 18.3457 22.1484 18.8088 21.5605 18.8088C20.9726 18.8088 20.496 18.3457 20.496 17.7743V15.4026L18.8079 17.0631C18.8077 17.0633 18.8074 17.0635 18.8072 17.0637C17.8661 17.9934 16.7475 18.7318 15.5151 19.236C14.2824 19.7402 12.9604 20 11.625 20C10.2895 20 8.96754 19.7402 7.73482 19.236C6.50213 18.7317 5.38337 17.9931 4.44208 17.0631C4.02982 16.6558 4.03539 16.0008 4.45454 15.6001C4.87368 15.1995 5.54768 15.2049 5.95994 15.6123C6.70499 16.3484 7.58894 16.9315 8.56083 17.3291C9.53268 17.7266 10.5739 17.931 11.625 17.931C12.6761 17.931 13.7173 17.7266 14.6891 17.3291C15.661 16.9315 16.545 16.3484 17.29 15.6123L17.2917 15.6106L19.0124 13.918H16.6031C16.0152 13.918 15.5386 13.4549 15.5386 12.8835Z"
        fill="#0D0C07"
      />
    </svg>
  );
};
