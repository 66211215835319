export const DashboardLogoIcon = () => {
  return (
    <svg width="143" height="12" viewBox="0 0 143 12" fill="none">
      <g opacity="0.5">
        <path
          d="M5.87483 4.127L4.99648 7.17812H6.86853L5.99811 4.127H5.87483Z"
          fill="#125C4F"
        />
        <path
          d="M9.6306 0H2.20872C0.989023 0 0 0.989022 0 2.20872V9.6306C0 10.8503 0.989023 11.8393 2.20872 11.8393H9.6306C10.8503 11.8393 11.8393 10.8503 11.8393 9.6306V2.20872C11.8393 0.989022 10.8503 0 9.6306 0ZM3.50174 8.21804L5.07353 2.97921H6.79148L8.36327 8.21804H3.50174Z"
          fill="#125C4F"
        />
        <path
          d="M15.4816 11.5846H17.4046L18.6121 8.56103H23.0935L24.2852 11.5846H26.224L21.695 0.445715H19.9948L15.4816 11.5846ZM19.2322 6.97009L20.8531 2.88045L22.4739 6.97009H19.2322Z"
          fill="#999999"
        />
        <path
          d="M28.8941 11.5846H30.6901L34.1544 3.18257H32.3744L29.7842 9.67473L27.2257 3.18257H25.4297L28.8941 11.5846Z"
          fill="#999999"
        />
        <path
          d="M37.6959 11.5846H39.4918L42.9562 3.18257H41.1761L38.5859 9.67473L36.0274 3.18257H34.2315L37.6959 11.5846Z"
          fill="#999999"
        />
        <path
          d="M43.8915 1.97314H45.7509V0.0954941H43.8915V1.97314ZM43.955 11.5846H45.6711V3.18257H43.955V11.5846Z"
          fill="#999999"
        />
        <path
          d="M51.2125 11.7438C52.9926 11.7438 54.1684 10.8846 54.7722 9.94556L53.4689 9.07048C53.008 9.77045 52.3883 10.2799 51.2121 10.2799C49.7976 10.2799 48.86 9.35719 48.733 7.92503H55.2643V7.24093C55.2643 5.12466 54.0568 3.0238 51.1962 3.0238C48.5742 3.0238 47.0486 5.15595 47.0486 7.41557C47.0486 10.4233 49.1626 11.7438 51.2125 11.7438ZM48.7969 6.49286C49.1149 5.10832 50.132 4.48773 51.2125 4.48773C52.7857 4.48773 53.3895 5.49029 53.5646 6.49286H48.7969Z"
          fill="#999999"
        />
        <path
          d="M59.9646 11.8393H63.644C66.8736 11.8393 69.1713 9.92727 69.1713 6.21564C69.1713 2.50402 66.8736 0.591965 63.644 0.591965H59.9646V11.8393ZM61.7641 10.2326V2.19873H63.6923C65.9257 2.19873 67.3717 3.46808 67.3717 6.21564C67.3717 8.96321 65.9257 10.2326 63.6923 10.2326H61.7641Z"
          fill="#999999"
        />
        <path
          d="M73.484 12C74.8015 12 75.621 11.5019 76.0548 10.8753C76.0869 11.1966 76.1673 11.5822 76.344 11.8393H77.9669C77.8062 11.4376 77.7098 10.8271 77.7098 10.2326V5.94249C77.7098 4.14292 76.4886 3.19493 74.3677 3.19493C72.7288 3.19493 71.5237 3.9019 70.8971 4.75349L71.9736 5.76575C72.5039 5.07484 73.259 4.67315 74.2713 4.67315C75.4924 4.67315 76.0387 5.09091 76.0387 5.74968C76.0387 6.44059 75.5246 6.82622 73.8214 6.82622C72.1343 6.82622 70.5275 7.53319 70.5275 9.39704C70.5275 11.1002 71.7969 12 73.484 12ZM73.7571 10.6021C72.8252 10.6021 72.2468 10.1362 72.2468 9.3649C72.2468 8.57759 72.8573 8.1277 73.9017 8.1277C75.2193 8.1277 75.7335 7.99915 76.0387 7.64567V8.54545C76.0387 9.73446 75.2193 10.6021 73.7571 10.6021Z"
          fill="#999999"
        />
        <path
          d="M83.0175 12C85.283 12 86.5363 10.811 86.5363 9.3649C86.5363 7.88668 85.5562 7.22791 83.7566 6.89049C82.2784 6.6334 81.4429 6.34419 81.4429 5.63721C81.4429 5.02664 82.0856 4.65708 83.0657 4.65708C83.9494 4.65708 84.6243 4.97844 85.1063 5.5408L86.2471 4.56068C85.5562 3.72516 84.4636 3.19493 83.0336 3.19493C81.2019 3.19493 79.7879 4.19112 79.7879 5.65328C79.7879 7.24397 81.1215 7.90275 82.6479 8.15983C84.1262 8.40085 84.8171 8.674 84.8171 9.3649C84.8171 10.0076 84.2065 10.5057 83.0496 10.5057C81.941 10.5057 81.2661 10.0237 80.8644 9.28457L79.3701 10.0237C79.9004 11.2127 81.4429 12 83.0175 12Z"
          fill="#999999"
        />
        <path
          d="M88.5051 11.8393H90.2404V7.54926C90.2404 5.86216 91.2045 4.67315 92.747 4.67315C93.9681 4.67315 94.4501 5.41226 94.4501 6.44059V11.8393H96.1854V6.42452C96.1854 4.27146 95.1571 3.19493 93.0683 3.19493C91.6865 3.19493 90.7224 3.8537 90.2404 4.64102V0.431289H88.5051V11.8393Z"
          fill="#999999"
        />
        <path
          d="M103.062 3.19493C101.793 3.19493 100.797 3.77336 100.283 4.4V0.431289H98.5474V11.8393H100.106L100.427 11.0199C101.022 11.6304 101.889 12 102.934 12C105.456 12 107.047 10.1683 107.047 7.5814C107.047 4.9945 105.456 3.19493 103.062 3.19493ZM102.773 10.5057C101.15 10.5057 100.154 9.18816 100.154 7.5814C100.154 5.9907 101.15 4.68922 102.773 4.68922C104.412 4.68922 105.312 5.9907 105.312 7.5814C105.312 9.18816 104.412 10.5057 102.773 10.5057Z"
          fill="#999999"
        />
        <path
          d="M112.757 12C115.264 12 117.063 10.1201 117.063 7.59746C117.063 5.07484 115.264 3.19493 112.757 3.19493C110.251 3.19493 108.467 5.07484 108.467 7.59746C108.467 10.1201 110.251 12 112.757 12ZM112.757 10.5057C111.215 10.5057 110.202 9.3167 110.202 7.59746C110.202 5.87822 111.215 4.68922 112.757 4.68922C114.316 4.68922 115.328 5.87822 115.328 7.59746C115.328 9.3167 114.316 10.5057 112.757 10.5057Z"
          fill="#999999"
        />
        <path
          d="M121.31 12C122.628 12 123.447 11.5019 123.881 10.8753C123.913 11.1966 123.994 11.5822 124.17 11.8393H125.793C125.633 11.4376 125.536 10.8271 125.536 10.2326V5.94249C125.536 4.14292 124.315 3.19493 122.194 3.19493C120.555 3.19493 119.35 3.9019 118.723 4.75349L119.8 5.76575C120.33 5.07484 121.085 4.67315 122.098 4.67315C123.319 4.67315 123.865 5.09091 123.865 5.74968C123.865 6.44059 123.351 6.82622 121.648 6.82622C119.961 6.82622 118.354 7.53319 118.354 9.39704C118.354 11.1002 119.623 12 121.31 12ZM121.584 10.6021C120.652 10.6021 120.073 10.1362 120.073 9.3649C120.073 8.57759 120.684 8.1277 121.728 8.1277C123.046 8.1277 123.56 7.99915 123.865 7.64567V8.54545C123.865 9.73446 123.046 10.6021 121.584 10.6021Z"
          fill="#999999"
        />
        <path
          d="M132.226 3.19493C131.197 3.19493 130.378 3.62875 129.928 4.30359L129.558 3.3556H127.984V11.8393H129.719V7.87061C129.719 5.87822 130.587 4.75349 132.081 4.75349C132.435 4.75349 132.74 4.80169 133.093 4.9463V3.33953C132.884 3.2592 132.595 3.19493 132.226 3.19493Z"
          fill="#999999"
        />
        <path
          d="M140.824 0.431289V4.4C140.342 3.69302 139.426 3.19493 138.14 3.19493C135.666 3.19493 134.059 4.9945 134.059 7.5814C134.059 10.1683 135.634 12 138.189 12C139.233 12 140.117 11.5501 140.679 10.9235L141 11.8393H142.559V0.431289H140.824ZM138.333 10.5057C136.694 10.5057 135.794 9.18816 135.794 7.5814C135.794 5.9907 136.71 4.68922 138.333 4.68922C139.956 4.68922 140.952 5.9907 140.952 7.5814C140.952 9.18816 139.956 10.5057 138.333 10.5057Z"
          fill="#999999"
        />
      </g>
    </svg>
  );
};
