export const MiniSearchIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      style={{marginLeft: '12px', marginTop: '4px'}}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.16699 3.33317C5.94533 3.33317 3.33366 5.94484 3.33366 9.1665C3.33366 12.3882 5.94533 14.9998 9.16699 14.9998C12.3887 14.9998 15.0003 12.3882 15.0003 9.1665C15.0003 5.94484 12.3887 3.33317 9.16699 3.33317ZM1.66699 9.1665C1.66699 5.02437 5.02486 1.6665 9.16699 1.6665C13.3091 1.6665 16.667 5.02437 16.667 9.1665C16.667 13.3086 13.3091 16.6665 9.16699 16.6665C5.02486 16.6665 1.66699 13.3086 1.66699 9.1665Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2861 13.2856C13.6115 12.9601 14.1391 12.9601 14.4646 13.2856L18.0896 16.9106C18.415 17.236 18.415 17.7637 18.0896 18.0891C17.7641 18.4145 17.2365 18.4145 16.9111 18.0891L13.2861 14.4641C12.9606 14.1387 12.9606 13.611 13.2861 13.2856Z"
        fill="black"
      />
    </svg>
  );
};
