import {FC} from 'react';

export const ScanResultIcon: FC<{type: number; small?: boolean}> = ({
  type,
  small,
}) => {
  return !type ? (
    small ? (
      <svg width="20" height="15" viewBox="0 0 20 15" fill="none">
        <g clipPath="url(#clip0_9478_67710)">
          <path
            d="M19.6875 3.63281C19.6875 3.94531 19.5708 4.21094 19.3375 4.42969L10.2875 12.9141L8.5875 14.5078C8.35417 14.7266 8.07083 14.8359 7.7375 14.8359C7.40417 14.8359 7.12083 14.7266 6.8875 14.5078L5.1875 12.9141L0.6625 8.67188C0.429167 8.45312 0.3125 8.1875 0.3125 7.875C0.3125 7.5625 0.429167 7.29688 0.6625 7.07812L2.3625 5.48438C2.59583 5.26562 2.87917 5.15625 3.2125 5.15625C3.54583 5.15625 3.82917 5.26562 4.0625 5.48438L7.7375 8.94141L15.9375 1.24219C16.1708 1.02344 16.4542 0.914062 16.7875 0.914062C17.1208 0.914062 17.4042 1.02344 17.6375 1.24219L19.3375 2.83594C19.5708 3.05469 19.6875 3.32031 19.6875 3.63281Z"
            fill="#69A40C"
          />
        </g>
        <defs>
          <clipPath id="clip0_9478_67710">
            <rect width="20" height="15" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ) : (
      <svg width="52" height="40" viewBox="0 0 52 40" fill="none">
        <path
          d="M51.7686 8.38917C51.7686 9.25736 51.4582 9.99532 50.8376 10.6031L26.7647 34.1744L22.2428 38.6021C21.6221 39.2098 20.8685 39.5137 19.9818 39.5137C19.0951 39.5137 18.3415 39.2098 17.7208 38.6021L13.1988 34.1744L1.16244 22.3887C0.541776 21.781 0.231445 21.043 0.231445 20.1748C0.231445 19.3066 0.541776 18.5687 1.16244 17.9609L5.6844 13.5332C6.30507 12.9255 7.05873 12.6216 7.94539 12.6216C8.83205 12.6216 9.58571 12.9255 10.2064 13.5332L19.9818 23.1375L41.7936 1.74753C42.4143 1.1398 43.168 0.835938 44.0546 0.835938C44.9413 0.835938 45.6949 1.1398 46.3156 1.74753L50.8376 6.17529C51.4582 6.78302 51.7686 7.52098 51.7686 8.38917Z"
          fill="#69A40C"
        />
      </svg>
    )
  ) : small ? (
    <svg width="21" height="19" viewBox="0 0 21 19" fill="none">
      <path
        d="M14.325 2H19L6.675 17H2L14.325 2Z"
        fill="#E0A203"
        stroke="#E0A203"
        strokeWidth="4"
        strokeLinejoin="round"
      />
      <path
        d="M6.675 2H2L14.325 17H19L6.675 2Z"
        fill="#E0A203"
        stroke="#E0A203"
        strokeWidth="4"
        strokeLinejoin="round"
      />
    </svg>
  ) : (
    <svg width="44" height="40" viewBox="0 0 44 40" fill="none">
      <mask
        id="mask0_9159_62002"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="44"
        height="40">
        <path
          d="M31 2H42L13 38H2L31 2Z"
          fill="white"
          stroke="white"
          strokeWidth="4"
          strokeLinejoin="round"
        />
        <path
          d="M13 2H2L31 38H42L13 2Z"
          fill="white"
          stroke="white"
          strokeWidth="4"
          strokeLinejoin="round"
        />
      </mask>
      <g mask="url(#mask0_9159_62002)">
        <path d="M-2 -4H46V44H-2V-4Z" fill="#E0A203" />
      </g>
    </svg>
  );
};
