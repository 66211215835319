export const MinusIcon = () => {
  return (
    <svg
      width="10"
      height="2"
      viewBox="0 0 10 2"
      fill="none"
      data-testid="minus-icon">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.16667 0H0.833333C0.3725 0 0 0.448 0 1C0 1.552 0.3725 2 0.833333 2H9.16667C9.6275 2 10 1.552 10 1C10 0.448 9.6275 0 9.16667 0Z"
        fill="white"
      />
    </svg>
  );
};
