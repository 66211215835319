import {FC} from 'react';

type TProps = {
  small?: boolean;
};

export const LogoutIcon: FC<TProps> = ({small}) => {
  return small ? (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.21997 2.71997H6.21908C6.6333 2.71997 6.96908 2.38418 6.96908 1.96997C6.96908 1.55576 6.6333 1.21997 6.21908 1.21997H2.06361C1.70725 1.21997 1.36549 1.36153 1.11351 1.61351C0.861533 1.86549 0.719971 2.20725 0.719971 2.56361V14.4364C0.719971 14.7927 0.861532 15.1345 1.11351 15.3865C1.3655 15.6385 1.70726 15.78 2.06361 15.78H6.21908C6.6333 15.78 6.96908 15.4442 6.96908 15.03C6.96908 14.6158 6.6333 14.28 6.21908 14.28H2.21997V2.71997ZM10.8831 4.85307C11.176 4.56017 11.6509 4.56017 11.9438 4.85307L15.0604 7.96967C15.3533 8.26256 15.3533 8.73744 15.0604 9.03033L11.9438 12.1469C11.6509 12.4398 11.176 12.4398 10.8831 12.1469C10.5902 11.854 10.5902 11.3792 10.8831 11.0863L12.7194 9.25H6.21908C5.80487 9.25 5.46908 8.91421 5.46908 8.5C5.46908 8.08579 5.80487 7.75 6.21908 7.75H12.7194L10.8831 5.91372C10.5902 5.62083 10.5902 5.14596 10.8831 4.85307Z"
        fill="#262626"
      />
    </svg>
  ) : (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g opacity="0.5">
        <path
          d="M6 2H15C15.5304 2 16.0391 2.21071 16.4142 2.58579C16.7893 2.96086 17 3.46957 17 4V6H15V4H6V20H15V18H17V20C17 20.5304 16.7893 21.0391 16.4142 21.4142C16.0391 21.7893 15.5304 22 15 22H6C5.46957 22 4.96086 21.7893 4.58579 21.4142C4.21071 21.0391 4 20.5304 4 20V4C4 3.46957 4.21071 2.96086 4.58579 2.58579C4.96086 2.21071 5.46957 2 6 2Z"
          fill="black"
        />
        <path
          d="M16.09 15.59L17.5 17L22.5 12L17.5 7L16.09 8.41L18.67 11H9V13H18.67L16.09 15.59Z"
          fill="black"
        />
      </g>
    </svg>
  );
};
