import {FC} from 'react';

export const ContactPhoneIcon: FC<{big?: boolean}> = ({big}) => {
  return big ? (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none">
      <path
        d="M20.48 14.6016L15.5481 12.4985C15.2937 12.3844 15.0143 12.3371 14.7365 12.361C14.4586 12.3849 14.1915 12.4793 13.9602 12.6352L11.3208 14.3913C9.71211 13.61 8.40966 12.315 7.6192 10.7108L9.36483 8.03976C9.51967 7.80829 9.61423 7.5418 9.63994 7.2645C9.66565 6.9872 9.62169 6.70787 9.51205 6.45188L7.39837 1.51996C7.25088 1.18694 7.00114 0.909557 6.68538 0.728039C6.36961 0.546521 6.00423 0.470304 5.64223 0.510442C4.22217 0.696471 2.91798 1.39185 1.97213 2.46729C1.02629 3.54272 0.503157 4.92504 0.5 6.35723C0.5 14.7068 7.29321 21.5 15.6428 21.5C17.075 21.4968 18.4573 20.9737 19.5327 20.0279C20.6082 19.082 21.3035 17.7778 21.4896 16.3578C21.5297 15.9958 21.4535 15.6304 21.272 15.3146C21.0904 14.9989 20.8131 14.7491 20.48 14.6016Z"
        fill="white"
      />
      <path
        d="M13.6869 2.96066C14.9674 3.31051 16.1346 3.98819 17.0732 4.92685C18.0119 5.8655 18.6896 7.03268 19.0394 8.31321C19.0858 8.49299 19.1904 8.65237 19.3369 8.7665C19.4833 8.88062 19.6634 8.94309 19.8491 8.94416C19.9238 8.94402 19.9982 8.9334 20.0699 8.91261C20.1769 8.88463 20.2772 8.83559 20.3649 8.76835C20.4527 8.70112 20.5261 8.61706 20.581 8.52108C20.6358 8.42511 20.6709 8.31915 20.6843 8.20942C20.6977 8.09969 20.689 7.9884 20.6588 7.88206C20.2382 6.31382 19.4124 4.88385 18.2643 3.73575C17.1162 2.58765 15.6862 1.76183 14.118 1.34123C14.0083 1.30125 13.8914 1.28456 13.7749 1.29222C13.6583 1.29988 13.5447 1.33172 13.4411 1.38571C13.3375 1.43971 13.2464 1.51468 13.1734 1.60584C13.1004 1.69701 13.0471 1.80238 13.0171 1.91524C12.987 2.0281 12.9808 2.14598 12.9988 2.26137C13.0168 2.37677 13.0587 2.48715 13.1217 2.58549C13.1847 2.68383 13.2675 2.76798 13.3648 2.83257C13.4621 2.89716 13.5718 2.94078 13.6869 2.96066Z"
        fill="white"
      />
      <path
        d="M12.8138 6.20993C13.5256 6.40477 14.1744 6.78167 14.6962 7.30351C15.218 7.82534 15.5949 8.47411 15.7898 9.1859C15.8345 9.36658 15.9387 9.52699 16.0855 9.6414C16.2324 9.75582 16.4134 9.8176 16.5995 9.81685C16.6708 9.81755 16.7418 9.8069 16.8098 9.7853C16.9177 9.75847 17.0191 9.71026 17.108 9.64353C17.1969 9.57679 17.2716 9.49289 17.3274 9.39679C17.3833 9.30069 17.4194 9.19434 17.4334 9.08406C17.4475 8.97378 17.4392 8.8618 17.4092 8.75475C17.1436 7.75524 16.6186 6.84366 15.8873 6.11237C15.156 5.38109 14.2445 4.85606 13.245 4.59049C13.1352 4.55052 13.0184 4.53382 12.9018 4.54148C12.7853 4.54914 12.6716 4.58098 12.568 4.63498C12.4645 4.68898 12.3733 4.76394 12.3003 4.85511C12.2273 4.94628 12.1741 5.05164 12.144 5.1645C12.114 5.27736 12.1078 5.39524 12.1258 5.51064C12.1438 5.62604 12.1856 5.73642 12.2486 5.83476C12.3116 5.9331 12.3944 6.01724 12.4917 6.08183C12.589 6.14643 12.6987 6.19005 12.8138 6.20993Z"
        fill="white"
      />
    </svg>
  ) : (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none">
      <path
        d="M13.7476 2.24558L11.9381 0.437816C11.8105 0.309614 11.6588 0.207893 11.4917 0.138499C11.3246 0.0691047 11.1455 0.0334039 10.9646 0.0334473C10.5959 0.0334473 10.2493 0.177865 9.98935 0.437816L8.04226 2.3849C7.91406 2.51255 7.81234 2.66426 7.74294 2.83133C7.67355 2.9984 7.63785 3.17754 7.63789 3.35845C7.63789 3.72714 7.78231 4.07374 8.04226 4.33369L9.46604 5.75748C9.13277 6.49207 8.66941 7.16034 8.09833 7.73005C7.52868 8.30252 6.86046 8.76756 6.12575 9.10286L4.70197 7.67908C4.57433 7.55088 4.42261 7.44916 4.25554 7.37976C4.08847 7.31037 3.90933 7.27467 3.72842 7.27471C3.35973 7.27471 3.01313 7.41913 2.75318 7.67908L0.804394 9.62447C0.676034 9.75234 0.574226 9.90434 0.504829 10.0717C0.435431 10.2391 0.399814 10.4185 0.400025 10.5997C0.400025 10.9684 0.544443 11.315 0.804394 11.575L2.61046 13.381C3.02503 13.7973 3.5976 14.0334 4.18546 14.0334C4.30949 14.0334 4.42842 14.0233 4.54566 14.0029C6.83595 13.6257 9.10755 12.4075 10.9408 10.5759C12.7724 8.74607 13.9889 6.47617 14.3711 4.17908C14.4867 3.47738 14.2539 2.75529 13.7476 2.24558Z"
        fill="#1A1A1F"
      />
    </svg>
  );
};
