import {FC} from 'react';

type TProps = {
  active: boolean;
};

export const GridIcon: FC<TProps> = ({active}) => {
  return active ? (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none">
      <path
        d="M6.63848 0.497559H2.06705C1.43587 0.497559 0.924194 1.00923 0.924194 1.64042V6.21184C0.924194 6.84303 1.43587 7.3547 2.06705 7.3547H6.63848C7.26966 7.3547 7.78134 6.84303 7.78134 6.21184V1.64042C7.78134 1.00923 7.26966 0.497559 6.63848 0.497559Z"
        fill="#625A28"
      />
      <path
        d="M15.7814 0.497559H11.2099C10.5788 0.497559 10.0671 1.00923 10.0671 1.64042V6.21184C10.0671 6.84303 10.5788 7.3547 11.2099 7.3547H15.7814C16.4125 7.3547 16.9242 6.84303 16.9242 6.21184V1.64042C16.9242 1.00923 16.4125 0.497559 15.7814 0.497559Z"
        fill="#625A28"
      />
      <path
        d="M6.63848 9.64044H2.06705C1.43587 9.64044 0.924194 10.1521 0.924194 10.7833V15.3547C0.924194 15.9859 1.43587 16.4976 2.06705 16.4976H6.63848C7.26966 16.4976 7.78134 15.9859 7.78134 15.3547V10.7833C7.78134 10.1521 7.26966 9.64044 6.63848 9.64044Z"
        fill="#625A28"
      />
      <path
        d="M15.7814 9.64044H11.2099C10.5788 9.64044 10.0671 10.1521 10.0671 10.7833V15.3547C10.0671 15.9859 10.5788 16.4976 11.2099 16.4976H15.7814C16.4125 16.4976 16.9242 15.9859 16.9242 15.3547V10.7833C16.9242 10.1521 16.4125 9.64044 15.7814 9.64044Z"
        fill="#625A28"
      />
    </svg>
  ) : (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none">
      <path
        d="M6.63848 0.497559H2.06705C1.43587 0.497559 0.924194 1.00923 0.924194 1.64042V6.21184C0.924194 6.84303 1.43587 7.3547 2.06705 7.3547H6.63848C7.26966 7.3547 7.78134 6.84303 7.78134 6.21184V1.64042C7.78134 1.00923 7.26966 0.497559 6.63848 0.497559Z"
        fill="black"
      />
      <path
        d="M15.7814 0.497559H11.2099C10.5788 0.497559 10.0671 1.00923 10.0671 1.64042V6.21184C10.0671 6.84303 10.5788 7.3547 11.2099 7.3547H15.7814C16.4125 7.3547 16.9242 6.84303 16.9242 6.21184V1.64042C16.9242 1.00923 16.4125 0.497559 15.7814 0.497559Z"
        fill="black"
      />
      <path
        d="M6.63848 9.64038H2.06705C1.43587 9.64038 0.924194 10.1521 0.924194 10.7832V15.3547C0.924194 15.9858 1.43587 16.4975 2.06705 16.4975H6.63848C7.26966 16.4975 7.78134 15.9858 7.78134 15.3547V10.7832C7.78134 10.1521 7.26966 9.64038 6.63848 9.64038Z"
        fill="black"
      />
      <path
        d="M15.7814 9.64038H11.2099C10.5788 9.64038 10.0671 10.1521 10.0671 10.7832V15.3547C10.0671 15.9858 10.5788 16.4975 11.2099 16.4975H15.7814C16.4125 16.4975 16.9242 15.9858 16.9242 15.3547V10.7832C16.9242 10.1521 16.4125 9.64038 15.7814 9.64038Z"
        fill="black"
      />
    </svg>
  );
};
