import {FC} from 'react';

type TProps = {
  opacity?: number;
  small?: boolean;
};

export const ArchiveIcon: FC<TProps> = ({opacity = 0.5, small = false}) => {
  return small ? (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.2989 8.03359V9.53103C12.5354 9.49521 12.7718 9.46655 13.0154 9.46655C13.259 9.46655 13.4955 9.49521 13.7319 9.53103V8.03359C13.7319 7.63789 13.4111 7.31711 13.0154 7.31711C12.6197 7.31711 12.2989 7.63789 12.2989 8.03359Z"
        fill="#262626"
      />
      <path
        d="M6.20887 8.03359H9.79127C9.99188 8.03359 10.1495 8.19122 10.1495 8.39183V9.06655C10.1495 9.28746 9.97042 9.46655 9.74951 9.46655H6.25063C6.02972 9.46655 5.85063 9.28746 5.85063 9.06655V8.39183C5.85063 8.19122 6.00826 8.03359 6.20887 8.03359Z"
        fill="#262626"
      />
      <path
        d="M3.70119 8.03359V12.7654C3.70119 13.3177 4.14891 13.7654 4.7012 13.7654H8.71655C8.71655 14.267 8.80969 14.747 8.96732 15.1984H4.26824C3.16367 15.1984 2.26824 14.3029 2.26824 13.1984V8.03359C2.26824 7.63789 2.58902 7.31711 2.98472 7.31711C3.38042 7.31711 3.70119 7.63789 3.70119 8.03359Z"
        fill="#262626"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4484 6.60063H3.55176C2.44719 6.60063 1.55176 5.7052 1.55176 4.60063V4.30176C1.55176 3.19719 2.44719 2.30176 3.55176 2.30176H12.4484C13.5529 2.30176 14.4484 3.19719 14.4484 4.30176V4.60063C14.4484 5.7052 13.5529 6.60063 12.4484 6.60063ZM2.98472 4.45119C2.98472 4.05549 3.3055 3.73472 3.7012 3.73472H12.2989C12.6946 3.73472 13.0154 4.05549 13.0154 4.45119C13.0154 4.8469 12.6946 5.16767 12.2989 5.16767H3.70119C3.30549 5.16767 2.98472 4.8469 2.98472 4.45119Z"
        fill="#262626"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5173 10.707C15.8173 10.9926 15.8288 11.4674 15.5432 11.7673L12.2098 15.2673C11.9299 15.5613 11.4668 15.5791 11.165 15.3075L9.49834 13.8075C9.19046 13.5304 9.1655 13.0562 9.4426 12.7483C9.71969 12.4405 10.1939 12.4155 10.5018 12.6926L11.6265 13.7048L14.457 10.7328C14.7426 10.4329 15.2174 10.4213 15.5173 10.707Z"
        fill="#262626"
      />
      <path
        d="M2.98472 4.45119C2.98472 4.05549 3.3055 3.73472 3.7012 3.73472H12.2989C12.6946 3.73472 13.0154 4.05549 13.0154 4.45119C13.0154 4.8469 12.6946 5.16767 12.2989 5.16767H3.70119C3.30549 5.16767 2.98472 4.8469 2.98472 4.45119Z"
        fill="#262626"
      />
    </svg>
  ) : (
    <svg
      opacity={opacity}
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none">
      <path
        d="M12.7825 8.48224V6.77088C12.7825 6.31865 13.1491 5.95205 13.6013 5.95205C14.0536 5.95205 14.4202 6.31865 14.4202 6.77088V8.48224C14.1499 8.4413 13.8797 8.40855 13.6013 8.40855C13.3229 8.40855 13.0527 8.4413 12.7825 8.48224ZM9.91658 6.77088H5.82242C5.59314 6.77088 5.413 6.95102 5.413 7.1803V8.00855C5.413 8.22946 5.59208 8.40855 5.813 8.40855H9.926C10.1469 8.40855 10.326 8.22946 10.326 8.00855V7.1803C10.326 6.95102 10.1459 6.77088 9.91658 6.77088ZM3.9565 13.3215C3.40422 13.3215 2.9565 12.8738 2.9565 12.3215V6.77088C2.9565 6.31865 2.5899 5.95205 2.13767 5.95205C1.68544 5.95205 1.31883 6.31865 1.31883 6.77088V12.9592C1.31883 14.0638 2.21426 14.9592 3.31883 14.9592H8.97492C8.79478 14.4433 8.68833 13.8947 8.68833 13.3215H3.9565ZM15.239 3.13321C15.239 4.23778 14.3436 5.13321 13.239 5.13321H2.5C1.39543 5.13321 0.5 4.23778 0.5 3.13321V2.22022C0.5 1.11565 1.39543 0.220215 2.5 0.220215H13.239C14.3436 0.220215 15.239 1.11565 15.239 2.22021V3.13321ZM13.6013 2.67671C13.6013 2.22449 13.2347 1.85788 12.7825 1.85788H2.9565C2.50427 1.85788 2.13767 2.22449 2.13767 2.67671C2.13767 3.12894 2.50427 3.49555 2.9565 3.49555H12.7825C13.2347 3.49555 13.6013 3.12894 13.6013 2.67671ZM16.5 11.5856L15.3373 10.4311L13.6013 12.167L11.8654 10.4311L10.7108 11.5856L12.4468 13.3215L10.7108 15.0575L11.8654 16.2202L13.6013 14.4761L15.3373 16.2202L16.5 15.0575L14.7559 13.3215L16.5 11.5856Z"
        fill="#0D0C07"
      />
    </svg>
  );
};
