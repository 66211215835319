import {FC} from 'react';

type TProps = {
  mobile?: boolean;
};

export const DashboardIcon: FC<TProps> = ({mobile = false}) => {
  return mobile ? (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M5.33333 16H1.77778C0.8 16 0 15.2 0 14.2222V1.77778C0 0.8 0.8 0 1.77778 0H5.33333C6.31111 0 7.11111 0.8 7.11111 1.77778V14.2222C7.11111 15.2 6.31111 16 5.33333 16ZM10.6667 16H14.2222C15.2 16 16 15.2 16 14.2222V9.77778C16 8.8 15.2 8 14.2222 8H10.6667C9.68889 8 8.88889 8.8 8.88889 9.77778V14.2222C8.88889 15.2 9.68889 16 10.6667 16ZM16 4.44444V1.77778C16 0.8 15.2 0 14.2222 0H10.6667C9.68889 0 8.88889 0.8 8.88889 1.77778V4.44444C8.88889 5.42222 9.68889 6.22222 10.6667 6.22222H14.2222C15.2 6.22222 16 5.42222 16 4.44444Z"
        fill="white"
      />
    </svg>
  ) : (
    <svg
      width="18"
      height="19"
      viewBox="0 0 21 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.26536 2.5C2.25877 2.50835 2.25 2.52517 2.25 2.55263V20.4474C2.25 20.4748 2.25877 20.4916 2.26536 20.5H7.23464C7.24123 20.4916 7.25 20.4748 7.25 20.4474V2.55263C7.25 2.52517 7.24123 2.50835 7.23464 2.5H2.26536ZM0.25 2.55263C0.25 1.46795 1.09772 0.5 2.25 0.5H7.25C8.40228 0.5 9.25 1.46795 9.25 2.55263V20.4474C9.25 21.5321 8.40228 22.5 7.25 22.5H2.25C1.09772 22.5 0.25 21.5321 0.25 20.4474V2.55263ZM13.25 2.5V6.81579H18.25V2.5H13.25ZM18.5459 6.77212C18.5457 6.77221 18.5455 6.77231 18.5453 6.7724L18.5459 6.77212ZM12.9541 6.77212C12.951 6.77072 12.9514 6.7707 12.9548 6.77241L12.9541 6.77212ZM12.1317 0.720596C12.4693 0.568265 12.8656 0.5 13.25 0.5H18.25C18.6344 0.5 19.0307 0.568265 19.3683 0.720596C19.613 0.830988 20.25 1.18926 20.25 1.95113V7.36466C20.25 8.12653 19.613 8.4848 19.3683 8.59519C19.0307 8.74752 18.6344 8.81579 18.25 8.81579H13.25C12.8656 8.81579 12.4693 8.74752 12.1317 8.59519C11.887 8.4848 11.25 8.12653 11.25 7.36466V1.95113C11.25 1.18926 11.887 0.830988 12.1317 0.720596ZM13.25 13.0263V20.5H18.25V13.0263H13.25ZM11.9825 11.3963C12.3498 11.1477 12.8009 11.0263 13.25 11.0263H18.25C18.6991 11.0263 19.1502 11.1477 19.5175 11.3963C19.8792 11.6411 20.25 12.0847 20.25 12.703V20.8233C20.25 21.4416 19.8792 21.8853 19.5175 22.13C19.1502 22.3786 18.6991 22.5 18.25 22.5H13.25C12.8009 22.5 12.3498 22.3786 11.9825 22.13C11.6208 21.8853 11.25 21.4416 11.25 20.8233V12.703C11.25 12.0847 11.6208 11.6411 11.9825 11.3963Z"
        fill="#0D0C07"
      />
    </svg>
  );
};
