export const BedsIcon = () => {
  return (
    <svg width="16" height="13" viewBox="0 0 16 13" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 0.75C1.41421 0.75 1.75 1.08579 1.75 1.5V2.75H13C13.7293 2.75 14.4288 3.03973 14.9445 3.55546C15.4603 4.07118 15.75 4.77065 15.75 5.5V11.5C15.75 11.9142 15.4142 12.25 15 12.25C14.5858 12.25 14.25 11.9142 14.25 11.5V9.75H1.75V11.5C1.75 11.9142 1.41421 12.25 1 12.25C0.585786 12.25 0.25 11.9142 0.25 11.5V1.5C0.25 1.08579 0.585786 0.75 1 0.75ZM1.75 4.25V8.25H5.75V4.25H1.75ZM7.25 4.25V8.25H14.25V5.5C14.25 5.16848 14.1183 4.85054 13.8839 4.61612C13.6495 4.3817 13.3315 4.25 13 4.25H7.25Z"
        fill="#B3B3B3"
      />
    </svg>
  );
};
