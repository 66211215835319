export const ReplyIcon = () => {
  return (
    <svg width="16" height="12" viewBox="0 0 16 12" fill="none">
      <path
        d="M15.0038 10.7459V10.7461C15.0038 10.9257 14.9324 11.098 14.8054 11.225C14.6784 11.352 14.5062 11.4233 14.3265 11.4233C14.1469 11.4233 13.9747 11.352 13.8477 11.225C13.7207 11.098 13.6494 10.9258 13.6493 10.7463C13.6476 9.2484 13.0518 7.81237 11.9927 6.75323C10.9336 5.69408 9.49753 5.09831 7.99968 5.09661H3.30794L5.31556 7.10364L5.31558 7.10366C5.44264 7.23072 5.51402 7.40305 5.51402 7.58273C5.51402 7.76242 5.44264 7.93475 5.31558 8.06181C5.18852 8.18887 5.01619 8.26025 4.8365 8.26025C4.65681 8.26025 4.48448 8.18887 4.35742 8.06181L1.19413 4.89852C1.19411 4.8985 1.19409 4.89848 1.19407 4.89846C1.13113 4.83558 1.0812 4.76091 1.04713 4.67872C1.01305 4.59651 0.995508 4.50838 0.995508 4.41938C0.995508 4.33039 1.01305 4.24226 1.04713 4.16005C1.08121 4.07783 1.13116 4.00314 1.19413 3.94025M15.0038 10.7459L1.30014 4.04637M15.0038 10.7459C15.0018 8.88901 14.2633 7.10872 12.9503 5.79568C11.6372 4.48264 9.85692 3.74412 8.00001 3.74216H7.99985H3.30794M15.0038 10.7459L2.94574 3.89216H3.1579L3.30794 3.74216M1.19413 3.94025L1.30014 4.04637M1.19413 3.94025L1.19407 3.94031L1.30014 4.04637M1.19413 3.94025L4.35742 0.776956C4.48448 0.649897 4.65681 0.578516 4.8365 0.578516C5.01619 0.578516 5.18852 0.649897 5.31558 0.776956C5.44264 0.904016 5.51402 1.07635 5.51402 1.25603C5.51402 1.43572 5.44264 1.60805 5.31558 1.73511L5.31556 1.73513L3.30794 3.74216M1.30014 4.04637L4.46349 0.883022C4.56241 0.784093 4.69659 0.728516 4.8365 0.728516C4.9764 0.728516 5.11058 0.784093 5.20951 0.883022C5.30844 0.981951 5.36402 1.11613 5.36402 1.25603C5.36402 1.39594 5.30844 1.53012 5.20951 1.62905L3.09578 3.74216H3.30794M2.94574 4.94661L3.09578 5.09661L3.1579 4.94661H2.94574Z"
        fill="#0D0C07"
        stroke="#0D0C07"
        strokeWidth="0.3"
      />
    </svg>
  );
};
