export const BigPersonIcon = () => {
  return (
    <svg width="82" height="90" viewBox="0 0 82 90" fill="none">
      <path
        d="M4.33398 85.9694V81.4277C4.33398 66.378 16.6461 54.1777 31.834 54.1777H50.1673C65.3551 54.1777 77.6673 66.378 77.6673 81.4277V85.9694"
        stroke="black"
        strokeWidth="8"
        strokeLinecap="round"
      />
      <path
        d="M41.0013 40.5526C30.8761 40.5526 22.668 32.4191 22.668 22.3859C22.668 12.3527 30.8761 4.21924 41.0013 4.21924C51.1263 4.21924 59.3346 12.3527 59.3346 22.3859C59.3346 32.4191 51.1263 40.5526 41.0013 40.5526Z"
        stroke="black"
        strokeWidth="8"
        strokeLinecap="round"
      />
    </svg>
  );
};
