export const CheckIcon = () => {
  return (
    <svg width="10" height="8" viewBox="0 0 10 8" fill="none">
      <path
        d="M1.50146 4.53665L3.6926 6.68396L8.40355 1.31567"
        stroke="white"
        strokeWidth="1.5338"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
