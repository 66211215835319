import {FC} from 'react';

type TProps = {
  color?: string;
  big?: boolean;
  notes?: boolean;
};

export const PlusIcon: FC<TProps> = ({color = 'black', big, notes}) => {
  return notes ? (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 0.75C8.41421 0.75 8.75 1.08579 8.75 1.5V7.25H14.5C14.9142 7.25 15.25 7.58579 15.25 8C15.25 8.41421 14.9142 8.75 14.5 8.75H8.75V14.5C8.75 14.9142 8.41421 15.25 8 15.25C7.58579 15.25 7.25 14.9142 7.25 14.5V8.75H1.5C1.08579 8.75 0.75 8.41421 0.75 8C0.75 7.58579 1.08579 7.25 1.5 7.25H7.25V1.5C7.25 1.08579 7.58579 0.75 8 0.75Z"
        fill="#0D0C07"
      />
    </svg>
  ) : big ? (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0004 0.183594C11.6184 0.183594 12.1194 0.684579 12.1194 1.30258V9.88144H20.6983C21.3163 9.88144 21.8173 10.3824 21.8173 11.0004C21.8173 11.6184 21.3163 12.1194 20.6983 12.1194H12.1194V20.6983C12.1194 21.3163 11.6184 21.8173 11.0004 21.8173C10.3824 21.8173 9.88144 21.3163 9.88144 20.6983V12.1194H1.30258C0.684579 12.1194 0.183594 11.6184 0.183594 11.0004C0.183594 10.3824 0.684579 9.88144 1.30258 9.88144H9.88144V1.30258C9.88144 0.684579 10.3824 0.183594 11.0004 0.183594Z"
        fill="#625A28"
      />
    </svg>
  ) : (
    <svg width="13" height="12" viewBox="0 0 13 12" fill="none">
      <path
        d="M12.25 6.75H7V12H5.5V6.75H0.25V5.25H5.5V0H7V5.25H12.25V6.75Z"
        fill={color}
      />
    </svg>
  );
};
