export const PlayIcon = () => {
  return (
    <svg width="105" height="104" viewBox="0 0 105 104" fill="none">
      <ellipse cx="52.4999" cy="52" rx="31.2" ry="31.2" fill="white" />
      <circle opacity="0.4" cx="52.4999" cy="52" r="42.64" fill="white" />
      <circle opacity="0.1" cx="52.5" cy="52" r="52" fill="white" />
      <path
        d="M59.013 50.6566L47.573 43.8934C46.6435 43.3441 45.22 43.8771 45.22 45.2356V58.7589C45.22 59.9776 46.5427 60.7121 47.573 60.1011L59.013 53.3411C60.0335 52.7399 60.0367 51.2579 59.013 50.6566V50.6566Z"
        fill="#26806F"
      />
    </svg>
  );
};
