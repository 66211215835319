export const SqFtIcon = () => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7802 2.33024L9.33811 3.77235L11.0344 5.46868C11.3273 5.76157 11.3273 6.23644 11.0344 6.52934C10.7415 6.82223 10.2667 6.82223 9.97377 6.52934L8.27744 4.83301L6.83487 6.27558L8.5312 7.97191C8.82409 8.2648 8.82409 8.73967 8.5312 9.03257C8.23831 9.32546 7.76343 9.32546 7.47054 9.03257L5.77421 7.33624L4.33164 8.77881L6.02797 10.4751C6.32086 10.768 6.32086 11.2429 6.02797 11.5358C5.73508 11.8287 5.2602 11.8287 4.96731 11.5358L3.27098 9.83947L1.83024 11.2802L5.21979 14.6698L14.1698 5.71979L10.7802 2.33024ZM9.85654 1.1326C10.3667 0.622467 11.1938 0.622467 11.7039 1.1326L15.3674 4.79611C15.8775 5.30625 15.8775 6.13333 15.3674 6.64346L6.14346 15.8674C5.63333 16.3775 4.80625 16.3775 4.29611 15.8674L0.632599 12.2039C0.122467 11.6938 0.122467 10.8667 0.632599 10.3565L9.85654 1.1326Z"
        fill="#B3B3B3"
      />
    </svg>
  );
};
