export const DollarIcon = () => {
  return (
    <svg width="11" height="16" viewBox="0 0 11 16" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.33301 0.75C5.74722 0.75 6.08301 1.08579 6.08301 1.5V2.25H6.33301C6.7598 2.25 7.18242 2.33406 7.57673 2.49739C7.97104 2.66072 8.32932 2.90011 8.63111 3.2019C8.9329 3.50369 9.17229 3.86197 9.33562 4.25628C9.49894 4.65059 9.58301 5.0732 9.58301 5.5C9.58301 5.91421 9.24722 6.25 8.83301 6.25C8.41879 6.25 8.08301 5.91421 8.08301 5.5C8.08301 5.27019 8.03774 5.04262 7.9498 4.8303C7.86185 4.61798 7.73295 4.42507 7.57044 4.26256C7.40794 4.10006 7.21502 3.97116 7.0027 3.88321C6.79038 3.79527 6.56282 3.75 6.33301 3.75H6.08301V7.25H6.83301C7.69496 7.25 8.52161 7.59241 9.13111 8.2019C9.7406 8.8114 10.083 9.63805 10.083 10.5C10.083 11.362 9.7406 12.1886 9.13111 12.7981C8.52161 13.4076 7.69496 13.75 6.83301 13.75H6.08301V14.5C6.08301 14.9142 5.74722 15.25 5.33301 15.25C4.91879 15.25 4.58301 14.9142 4.58301 14.5V13.75H3.83301C2.97105 13.75 2.1444 13.4076 1.53491 12.7981C0.925418 12.1886 0.583008 11.362 0.583008 10.5C0.583008 10.0858 0.918794 9.75 1.33301 9.75C1.74722 9.75 2.08301 10.0858 2.08301 10.5C2.08301 10.9641 2.26738 11.4092 2.59557 11.7374C2.92376 12.0656 3.36888 12.25 3.83301 12.25H4.58301V8.75H4.08301C3.22105 8.75 2.3944 8.40759 1.78491 7.7981C1.17542 7.1886 0.833008 6.36195 0.833008 5.5C0.833008 4.63805 1.17542 3.8114 1.78491 3.2019C2.3944 2.59241 3.22105 2.25 4.08301 2.25H4.58301V1.5C4.58301 1.08579 4.91879 0.75 5.33301 0.75ZM4.58301 3.75H4.08301C3.61888 3.75 3.17376 3.93437 2.84557 4.26256C2.51738 4.59075 2.33301 5.03587 2.33301 5.5C2.33301 5.96413 2.51738 6.40925 2.84557 6.73744C3.17376 7.06563 3.61888 7.25 4.08301 7.25H4.58301V3.75ZM6.08301 8.75V12.25H6.83301C7.29714 12.25 7.74226 12.0656 8.07044 11.7374C8.39863 11.4092 8.58301 10.9641 8.58301 10.5C8.58301 10.0359 8.39863 9.59075 8.07044 9.26256C7.74226 8.93437 7.29714 8.75 6.83301 8.75H6.08301Z"
        fill="#0D0C07"
      />
    </svg>
  );
};
