export const BoldCheckIcon = () => {
  return (
    <svg width="20" height="17" viewBox="0 0 20 17" fill="none">
      <path
        d="M15.9936 0.960938H17.0082C18.2526 1.31879 19.0401 2.11819 19.3501 3.3805V4.16111C19.1323 4.58472 18.9837 5.06471 18.6865 5.4217C15.8382 8.84308 12.9574 12.238 10.1057 15.6568C9.59839 16.2649 9.02959 16.7372 8.26691 16.9609H7.09599C6.43238 16.7577 5.9097 16.3477 5.40666 15.8814C4.082 14.6524 2.74711 13.4337 1.38744 12.2448C0.800698 11.7324 0.34292 11.1661 0.149902 10.4052V9.54682C0.191751 9.42469 0.234454 9.30171 0.275449 9.17872C0.863898 7.39971 3.12033 6.36288 4.82675 7.97535C5.60138 8.70728 6.42043 9.39309 7.20104 10.1199C7.47946 10.3795 7.63747 10.3548 7.8766 10.0601C9.94856 7.50305 12.0436 4.96563 14.1121 2.40601C14.6305 1.76461 15.1848 1.20435 15.9953 0.960938H15.9936Z"
        fill="#26806F"
      />
    </svg>
  );
};
