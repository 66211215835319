import {FC} from 'react';

export const OfferTypeIcon: FC<{gold?: boolean}> = ({gold}) => {
  return (
    <svg width="14" height="16" viewBox="0 0 14 16" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.25516 1.81065V2.52826H7.59202C7.804 2.52826 8.00747 2.61163 8.1585 2.76037L11.1186 5.67564C11.2727 5.82738 11.3594 6.03458 11.3594 6.25083V11.8571H12.1129V4.25663L9.62932 1.81065H4.25516ZM11.3594 13.4717H12.3282C12.6954 13.4717 13.05 13.3282 13.3133 13.0688C13.5771 12.8091 13.7275 12.4541 13.7275 12.0814V3.91862C13.7275 3.70237 13.6408 3.49516 13.4867 3.34343L10.5266 0.428158C10.3756 0.279417 10.1721 0.196045 9.96012 0.196045H4.03988C3.6727 0.196045 3.31812 0.339582 3.05478 0.598931C2.79103 0.858691 2.64055 1.21364 2.64055 1.5864V2.52826H1.67179C1.30461 2.52826 0.950028 2.6718 0.686689 2.93114C0.422932 3.1909 0.272461 3.54586 0.272461 3.91862V14.4136C0.272461 14.7863 0.422932 15.1413 0.686689 15.4011C0.950029 15.6604 1.30461 15.8039 1.67179 15.8039H9.96012C10.3273 15.8039 10.6819 15.6604 10.9452 15.4011C11.209 15.1413 11.3594 14.7863 11.3594 14.4136V13.4717ZM9.74484 6.58884L7.26123 4.14287H1.88707V14.1893H9.74484V6.58884ZM3.23258 9.74915C3.23258 9.30329 3.59402 8.94185 4.03988 8.94185H7.59202C8.03789 8.94185 8.39933 9.30329 8.39933 9.74915C8.39933 10.195 8.03789 10.5565 7.59202 10.5565H4.03988C3.59402 10.5565 3.23258 10.195 3.23258 9.74915ZM3.23258 12.0814C3.23258 11.6355 3.59402 11.2741 4.03988 11.2741H7.59202C8.03789 11.2741 8.39933 11.6355 8.39933 12.0814C8.39933 12.5272 8.03789 12.8887 7.59202 12.8887H4.03988C3.59402 12.8887 3.23258 12.5272 3.23258 12.0814Z"
        fill={gold ? '#4D4217' : 'black'}
      />
    </svg>
  );
};
