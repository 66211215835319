import {FC} from 'react';

type TProps = {
  active: boolean;
};

export const BurgerIcon: FC<TProps> = ({active = false}) => {
  return !active ? (
    <svg width="34" height="34" viewBox="0 0 34 34" fill="none">
      <rect width="34" height="34" rx="8" fill="#125C4F" />
      <path
        d="M9 25H25M9 17H25M9 9H25"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      style={{
        boxShadow: '0px 0px 16px 0px rgba(0, 0, 0, 0.16)',
        borderRadius: '8px',
      }}>
      <rect width="34" height="34" rx="8" fill="white" />
      <path
        d="M9 25H25M9 17H25M9 9H25"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
