import {FC} from 'react';

type TProps = {
  active: boolean;
};

export const ListIcon: FC<TProps> = ({active}) => {
  return active ? (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none">
      <path
        d="M16.9242 16.3991L5.56543 16.4976V13.8473L16.9242 13.7489V16.3991ZM3.57633 16.4976H0.924194V13.8473H3.57633V16.4976ZM16.9242 9.77346L5.56543 9.87191V7.22165L16.9242 7.12321V9.77346ZM3.57633 9.87191H0.924194V7.22165H3.57633V9.87191ZM16.9242 3.14782L5.59593 3.24627V0.596006L16.9242 0.497559V3.14782ZM3.57633 3.24627H0.924194V0.596006H3.57633V3.24627Z"
        fill="#625A28"
      />
    </svg>
  ) : (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none">
      <path
        d="M16.9242 16.3991L5.56543 16.4976V13.8473L16.9242 13.7489V16.3991ZM3.57633 16.4976H0.924194V13.8473H3.57633V16.4976ZM16.9242 9.77346L5.56543 9.87191V7.22165L16.9242 7.12321V9.77346ZM3.57633 9.87191H0.924194V7.22165H3.57633V9.87191ZM16.9242 3.14782L5.59593 3.24627V0.596006L16.9242 0.497559V3.14782ZM3.57633 3.24627H0.924194V0.596006H3.57633V3.24627Z"
        fill="black"
      />
    </svg>
  );
};
