export const ListingIcon = () => {
  return (
    <svg width="32" height="33" viewBox="0 0 32 33" fill="none">
      <rect y="0.297852" width="32" height="32" rx="4" fill="#125C4F" />
      <path
        d="M23.004 23.2979H9.05063H9.04288C8.60877 23.2901 8.26769 22.949 8.26769 22.5149V22.5141V16.6847L8.25994 16.677C8.25219 16.4522 8.35296 16.2351 8.5235 16.0878L11.3529 13.608V10.0809C11.3452 9.64681 11.694 9.29798 12.1204 9.29798H22.973H22.9653C23.3916 9.29023 23.7405 9.63906 23.7405 10.0654V22.4684C23.7405 22.8948 23.3916 23.2436 22.9653 23.2436L23.004 23.2979ZM13.7017 21.7475H16.0273V17.0514L12.9266 14.346L9.82582 17.0436V21.7397H12.1514V18.639H13.7017V21.7397V21.7475ZM17.5777 21.7475H22.2288V10.8949H12.9266V12.5383C13.1049 12.5383 13.2831 12.6003 13.4359 12.7243L17.3118 16.1033C17.4746 16.2429 17.5754 16.4599 17.5754 16.6847V21.7312L17.5777 21.7475ZM19.1281 15.546H20.6785V17.0964H19.1281V15.546ZM19.1281 18.6467H20.6785V20.1971H19.1281V18.6467ZM19.1281 12.4452H20.6785V13.9956H19.1281V12.4452ZM16.0273 12.4452H17.5777V13.9956H16.0273V12.4452Z"
        fill="white"
      />
    </svg>
  );
};
