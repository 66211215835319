import colors from '@/theme/colors/colors';
import {FC} from 'react';

export const LoaderIcon: FC<{size?: number}> = ({size = 44}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid">
      <circle
        cx="50"
        cy="50"
        fill="none"
        stroke={colors.accent_1}
        strokeWidth="10"
        r="35"
        strokeDasharray="164.93361431346415 56.97787143782138"
      />
    </svg>
  );
};
