import {FC} from 'react';

export const ListingTypeIcon: FC<{gold?: boolean}> = ({gold}) => {
  return (
    <svg width="16" height="14" viewBox="0 0 16 14" fill="none">
      <path
        d="M15.004 14H1.05063H1.04288C0.608772 13.9922 0.26769 13.6512 0.26769 13.2171V13.2163V7.38688L0.259939 7.37913C0.252187 7.15433 0.352961 6.93727 0.523502 6.78999L3.35293 4.31017V0.783065C3.34518 0.348961 3.69402 0.00012675 4.12037 0.00012675H14.973H14.9653C15.3916 -0.00762512 15.7405 0.341209 15.7405 0.767562V13.1706C15.7405 13.5969 15.3916 13.9457 14.9653 13.9457L15.004 14ZM5.70175 12.4496H8.02735V7.75354L4.92656 5.04814L1.82582 7.74579V12.4419H4.15138V9.34113H5.70175V12.4419V12.4496ZM9.57772 12.4496H14.2288V1.59701H4.92656V3.24041C5.10486 3.24041 5.28315 3.30243 5.43586 3.42646L9.31183 6.80549C9.47462 6.94502 9.5754 7.16208 9.5754 7.38688V12.4333L9.57772 12.4496ZM11.1281 6.24813H12.6785V7.79851H11.1281V6.24813ZM11.1281 9.34888H12.6785V10.8993H11.1281V9.34888ZM11.1281 3.14739H12.6785V4.69776H11.1281V3.14739ZM8.02735 3.14739H9.57772V4.69776H8.02735V3.14739Z"
        fill={gold ? '#4D4217' : 'black'}
      />
    </svg>
  );
};
