import {FC} from 'react';
import styled from 'styled-components';
export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 27px;
  height: 27px;
  background: #fff;
  box-shadow: 0 0 16px rgb(0 0 0 / 16%);
  border-radius: 2px;
`;
type TProps = {
  back?: boolean;
  color?: string;
};
export const SellerIcon: FC<TProps> = ({back = true, color = 'black'}) => {
  return back ? (
    <Wrapper>
      <svg width="17" height="15" viewBox="0 0 17 15" fill="none">
        <path
          d="M15.4515 14.4824H1.49814H1.49039C1.05628 14.4747 0.7152 14.1336 0.7152 13.6995V13.6987V7.8693L0.707448 7.86155C0.699696 7.63675 0.800471 7.41969 0.971012 7.27241L3.80044 4.79259V1.26549C3.79269 0.831383 4.14153 0.482549 4.56788 0.482549H15.4205H15.4128C15.8391 0.474797 16.188 0.823631 16.188 1.24998V13.653C16.188 14.0793 15.8391 14.4282 15.4128 14.4282L15.4515 14.4824ZM6.14926 12.932H8.47486V8.23597L5.37407 5.53056L2.27333 8.22822V12.9243H4.59889V9.82355H6.14926V12.9243V12.932ZM10.0252 12.932H14.6764V2.07943H5.37407V3.72283C5.55237 3.72283 5.73066 3.78485 5.88337 3.90888L9.75935 7.28791C9.92213 7.42745 10.0229 7.6445 10.0229 7.8693V12.9158L10.0252 12.932ZM11.5756 6.73055H13.126V8.28093H11.5756V6.73055ZM11.5756 9.8313H13.126V11.3817H11.5756V9.8313ZM11.5756 3.62981H13.126V5.18018H11.5756V3.62981ZM8.47486 3.62981H10.0252V5.18018H8.47486V3.62981Z"
          fill="black"
        />
      </svg>
    </Wrapper>
  ) : (
    <svg
      width="17"
      height="15"
      viewBox="0 0 17 15"
      fill="none"
      style={{marginRight: '4px'}}>
      <path
        d="M15.4515 14.4824H1.49814H1.49039C1.05628 14.4747 0.7152 14.1336 0.7152 13.6995V13.6987V7.8693L0.707448 7.86155C0.699696 7.63675 0.800471 7.41969 0.971012 7.27241L3.80044 4.79259V1.26549C3.79269 0.831383 4.14153 0.482549 4.56788 0.482549H15.4205H15.4128C15.8391 0.474797 16.188 0.823631 16.188 1.24998V13.653C16.188 14.0793 15.8391 14.4282 15.4128 14.4282L15.4515 14.4824ZM6.14926 12.932H8.47486V8.23597L5.37407 5.53056L2.27333 8.22822V12.9243H4.59889V9.82355H6.14926V12.9243V12.932ZM10.0252 12.932H14.6764V2.07943H5.37407V3.72283C5.55237 3.72283 5.73066 3.78485 5.88337 3.90888L9.75935 7.28791C9.92213 7.42745 10.0229 7.6445 10.0229 7.8693V12.9158L10.0252 12.932ZM11.5756 6.73055H13.126V8.28093H11.5756V6.73055ZM11.5756 9.8313H13.126V11.3817H11.5756V9.8313ZM11.5756 3.62981H13.126V5.18018H11.5756V3.62981ZM8.47486 3.62981H10.0252V5.18018H8.47486V3.62981Z"
        fill={color}
      />
    </svg>
  );
};
