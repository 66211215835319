export const RefreshRoundedIcon = () => {
  return (
    <svg
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.331 9.68676C21.3312 12.1939 20.3441 14.6003 18.5833 16.385C16.8225 18.1698 14.4297 19.1894 11.9228 19.2231H11.7946C9.35915 19.2291 7.01485 18.2971 5.24836 16.6204C5.1725 16.5487 5.11152 16.4627 5.0689 16.3674C5.02627 16.2721 5.00284 16.1693 4.99993 16.0649C4.99702 15.9606 5.0147 15.8566 5.05196 15.7591C5.08922 15.6616 5.14532 15.5723 5.21707 15.4965C5.28882 15.4206 5.3748 15.3596 5.47012 15.317C5.56544 15.2744 5.66821 15.2509 5.77259 15.248C5.87696 15.2451 5.98088 15.2628 6.07842 15.3001C6.17596 15.3373 6.26521 15.3934 6.34106 15.4652C7.47727 16.5367 8.90403 17.2497 10.4431 17.5151C11.9822 17.7804 13.5653 17.5864 14.9948 16.9573C16.4242 16.3281 17.6366 15.2917 18.4805 13.9776C19.3243 12.6634 19.7622 11.1297 19.7395 9.56809C19.7168 8.00647 19.2344 6.48617 18.3527 5.1971C17.471 3.90803 16.229 2.90735 14.7818 2.32006C13.3347 1.73276 11.7465 1.5849 10.2158 1.89494C8.68514 2.20498 7.27973 2.95918 6.17517 4.06331C6.16706 4.07209 6.15843 4.08038 6.14934 4.08815L3.50898 6.50799H6.23179C6.44256 6.50799 6.64469 6.59171 6.79373 6.74075C6.94276 6.88978 7.02649 7.09191 7.02649 7.30268C7.02649 7.51344 6.94276 7.71558 6.79373 7.86461C6.64469 8.01364 6.44256 8.09737 6.23179 8.09737H1.46364C1.25287 8.09737 1.05074 8.01364 0.901705 7.86461C0.752672 7.71558 0.668945 7.51344 0.668945 7.30268V2.53452C0.668945 2.32376 0.752672 2.12162 0.901705 1.97259C1.05074 1.82356 1.25287 1.73983 1.46364 1.73983C1.6744 1.73983 1.87654 1.82356 2.02557 1.97259C2.1746 2.12162 2.25833 2.32376 2.25833 2.53452V5.49475L5.06459 2.93187C6.39965 1.60199 8.09879 0.697459 9.94751 0.33246C11.7962 -0.032539 13.7116 0.158358 15.4519 0.881053C17.1923 1.60375 18.6795 2.82584 19.7258 4.39306C20.7721 5.96027 21.3307 7.80235 21.331 9.68676Z"
        fill="#0D0C07"
      />
    </svg>
  );
};
