export const CopyIcon = () => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none">
      <g opacity="0.5">
        <path
          d="M11 10.6084H9V13.6084H6V15.6084H9V18.6084H11V15.6084H14V13.6084H11V10.6084Z"
          fill="black"
        />
        <path
          d="M4 22.6084H16C17.103 22.6084 18 21.7114 18 20.6084V8.6084C18 7.5054 17.103 6.6084 16 6.6084H4C2.897 6.6084 2 7.5054 2 8.6084V20.6084C2 21.7114 2.897 22.6084 4 22.6084ZM4 8.6084H16L16.002 20.6084H4V8.6084Z"
          fill="black"
        />
        <path
          d="M20 2.6084H8V4.6084H20V16.6084H22V4.6084C22 3.5054 21.103 2.6084 20 2.6084Z"
          fill="black"
        />
      </g>
    </svg>
  );
};
