import {FC} from 'react';

type TProps = {
  double?: boolean;
  small?: boolean;
  stepIcon?: boolean;
  notes?: boolean;
};

export const CheckMarkIcon: FC<TProps> = ({double, small, stepIcon, notes}) => {
  return notes ? (
    <svg width="16" height="11" viewBox="0 0 16 11" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9669 0.40717C15.2597 0.700063 15.2597 1.17494 14.9669 1.46783L6.38482 10.0499C6.09193 10.3428 5.61705 10.3428 5.32416 10.0499L1.03315 5.75885C0.740253 5.46595 0.740253 4.99108 1.03315 4.69819C1.32604 4.40529 1.80091 4.40529 2.09381 4.69819L5.85449 8.45887L13.9062 0.40717C14.1991 0.114277 14.674 0.114277 14.9669 0.40717Z"
        fill="#0D0C07"
      />
    </svg>
  ) : stepIcon ? (
    <svg width="16" height="11" viewBox="0 0 16 11" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9659 0.777775C15.2588 1.07067 15.2588 1.54554 14.9659 1.83844L6.38385 10.4205C6.09095 10.7134 5.61608 10.7134 5.32319 10.4205L1.03217 6.12945C0.739277 5.83656 0.739277 5.36168 1.03217 5.06879C1.32506 4.7759 1.79994 4.7759 2.09283 5.06879L5.85352 8.82948L13.9052 0.777775C14.1981 0.484882 14.673 0.484882 14.9659 0.777775Z"
        fill="#F2FF43"
      />
    </svg>
  ) : double ? (
    small ? (
      <svg width="21" height="17" viewBox="0 0 21 17" fill="none">
        <path
          d="M12.0549 5.22534L4.77328 12.507L1.13245 8.86618M19.6675 5.22534L12.3858 12.507L10.4496 10.5707"
          stroke="#262626"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ) : (
      <svg width="22" height="16" viewBox="0 0 22 16" fill="none">
        <path
          d="M12.6549 4.35938L5.37326 11.641L1.73242 8.00021M20.2675 4.35938L12.9858 11.641L11.0496 9.70478"
          stroke="#528909"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )
  ) : (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9669 3.17865C15.2597 3.47155 15.2597 3.94642 14.9669 4.23931L6.38482 12.8213C6.09193 13.1142 5.61705 13.1142 5.32416 12.8213L1.03315 8.53033C0.740253 8.23744 0.740253 7.76256 1.03315 7.46967C1.32604 7.17678 1.80091 7.17678 2.09381 7.46967L5.85449 11.2304L13.9062 3.17865C14.1991 2.88576 14.674 2.88576 14.9669 3.17865Z"
        fill="#262626"
      />
    </svg>
  );
};
