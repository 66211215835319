export const SelectArrowIcon = () => {
  return (
    <svg width="11" height="6" viewBox="0 0 11 6" fill="none">
      <path
        d="M0.5 0.497559L5.5 5.49756L10.5 0.49756L0.5 0.497559Z"
        fill="black"
      />
    </svg>
  );
};
