export const SendIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.93862 0.755591C2.18751 0.733057 2.44148 0.778672 2.6687 0.891783L2.6724 0.893624L14.5445 6.88531C14.5451 6.88564 14.5458 6.88597 14.5465 6.8863C14.7459 6.98615 14.9222 7.13722 15.0497 7.33195C15.1781 7.5282 15.25 7.75911 15.25 8C15.25 8.24088 15.1781 8.47179 15.0497 8.66804C14.9222 8.86278 14.7459 9.01384 14.5465 9.11369C14.5458 9.11402 14.5451 9.11436 14.5445 9.11469L2.66871 15.1082C2.44148 15.2213 2.18751 15.2669 1.93862 15.2444C1.68969 15.2219 1.44604 15.1311 1.24164 14.9744C1.03646 14.8171 0.877104 14.5974 0.79995 14.337C0.722467 14.0755 0.735675 13.7979 0.835763 13.5458C0.835836 13.5456 0.835689 13.546 0.835763 13.5458L3.02991 8L0.835983 2.45477C0.83589 2.45453 0.836076 2.455 0.835983 2.45477C0.735948 2.20265 0.722482 1.92441 0.79995 1.66299C0.877104 1.40262 1.03646 1.18293 1.24164 1.02563C1.44604 0.86891 1.68969 0.778128 1.93862 0.755591ZM2.45227 2.46275L4.34631 7.25H8.33895C8.75316 7.25 9.08895 7.58578 9.08895 8C9.08895 8.41421 8.75316 8.75 8.33895 8.75H4.34631L2.45227 13.5372L13.424 8L2.45227 2.46275Z"
        fill="#0D0C07"
      />
    </svg>
  );
};
