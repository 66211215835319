export const HouseIcon = () => {
  return (
    <svg width="26" height="21" viewBox="0 0 26 21" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7142 0.687582C12.0727 0.375009 12.5318 0.208252 13 0.208252C13.4682 0.208252 13.9273 0.37501 14.2858 0.687584C14.2863 0.68807 14.2869 0.688557 14.2874 0.689044L22.7966 8.07581L22.8121 8.08953C23.0047 8.26327 23.1619 8.47481 23.2719 8.71322C23.382 8.95178 23.4418 9.211 23.4463 9.47522L23.4464 9.49356V18.6249H24.9167C25.515 18.6249 26 19.1099 26 19.7083C26 20.3066 25.515 20.7916 24.9167 20.7916H1.08333C0.485025 20.7916 0 20.3066 0 19.7083C0 19.1099 0.485025 18.6249 1.08333 18.6249H2.55357V9.49356L2.55373 9.47522C2.5582 9.21101 2.61802 8.95178 2.72807 8.71322C2.83806 8.47481 2.99528 8.26327 3.18786 8.08953L3.20337 8.07581L11.7126 0.689044C11.7131 0.688557 11.7137 0.688069 11.7142 0.687582ZM4.72024 18.6249H9.3631V14.0165C9.3631 13.4971 9.57954 13.0096 9.94743 12.6582C10.3136 12.3084 10.8001 12.12 11.2976 12.12H14.7024C15.1999 12.12 15.6864 12.3084 16.0526 12.6582C16.4205 13.0096 16.6369 13.4971 16.6369 14.0165V18.6249H21.2798V9.62819L13 2.44059L4.72024 9.62819V18.6249ZM14.4702 18.6249V14.2867H11.5298V18.6249H14.4702Z"
        fill="#262626"
      />
    </svg>
  );
};
