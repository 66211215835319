export const SuccessIcon = () => {
  return (
    <svg width="95" height="94" viewBox="0 0 95 94" fill="none">
      <circle
        cx="47.5"
        cy="47.2695"
        r="45.082"
        stroke="#69A40C"
        strokeWidth="3"
      />
      <path
        d="M77.9828 34.8403C77.9828 35.8636 77.6157 36.7335 76.8814 37.4498L48.4039 65.2328L43.0545 70.4517C42.3203 71.168 41.4287 71.5261 40.3798 71.5261C39.3309 71.5261 38.4393 71.168 37.7051 70.4517L32.3557 65.2328L18.117 51.3413C17.3827 50.625 17.0156 49.7551 17.0156 48.7318C17.0156 47.7085 17.3827 46.8387 18.117 46.1224L23.4663 40.9035C24.2006 40.1871 25.0921 39.829 26.141 39.829C27.1899 39.829 28.0815 40.1871 28.8157 40.9035L40.3798 52.2239L66.1827 27.012C66.9169 26.2957 67.8085 25.9375 68.8574 25.9375C69.9063 25.9375 70.7978 26.2957 71.532 27.012L76.8814 32.2309C77.6157 32.9472 77.9828 33.817 77.9828 34.8403Z"
        fill="#69A40C"
      />
    </svg>
  );
};
