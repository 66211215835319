export const UserSettingsIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g opacity="0.5">
        <path
          d="M21.5893 18.75H20.0893V17.2501H20.8393V14.2501H17.8394V15.0001H16.3394V13.5001C16.3394 13.3011 16.4184 13.1104 16.559 12.9697C16.6997 12.8291 16.8904 12.7501 17.0894 12.7501H21.5893C21.7883 12.7501 21.979 12.8291 22.1197 12.9697C22.2603 13.1104 22.3393 13.3011 22.3393 13.5001V18C22.3393 18.199 22.2603 18.3897 22.1197 18.5304C21.979 18.671 21.7883 18.75 21.5893 18.75Z"
          fill="black"
        />
        <path
          d="M17.8391 22.5H13.3391C13.1402 22.5 12.9494 22.421 12.8088 22.2803C12.6681 22.1397 12.5891 21.9489 12.5891 21.75V17.25C12.5891 17.0511 12.6681 16.8603 12.8088 16.7197C12.9494 16.579 13.1402 16.5 13.3391 16.5H17.8391C18.038 16.5 18.2288 16.579 18.3694 16.7197C18.5101 16.8603 18.5891 17.0511 18.5891 17.25V21.75C18.5891 21.9489 18.5101 22.1397 18.3694 22.2803C18.2288 22.421 18.038 22.5 17.8391 22.5ZM14.0891 21H17.0891V18H14.0891V21ZM11.0891 14.8935C10.5472 14.7523 10.0557 14.4619 9.67053 14.0554C9.28534 13.6489 9.02184 13.1426 8.90997 12.5938C8.7981 12.0451 8.84233 11.476 9.03764 10.9511C9.23295 10.4263 9.57152 9.96671 10.0149 9.6246C10.4583 9.28249 10.9887 9.07155 11.5459 9.01575C12.1032 8.95994 12.6649 9.0615 13.1673 9.30889C13.6697 9.55628 14.0927 9.93959 14.3882 10.4153C14.6837 10.891 14.8399 11.44 14.8391 12H16.3391C16.34 11.1419 16.0954 10.3014 15.6344 9.57764C15.1733 8.85388 14.5149 8.27706 13.7368 7.91518C12.9586 7.5533 12.0933 7.42145 11.2427 7.53518C10.3921 7.64891 9.59181 8.00348 8.93611 8.55708C8.28041 9.11068 7.79668 9.84022 7.54194 10.6597C7.28721 11.4792 7.2721 12.3544 7.49839 13.1821C7.72469 14.0099 8.18295 14.7557 8.81915 15.3316C9.45535 15.9075 10.243 16.2895 11.0891 16.4325V14.8935Z"
          fill="black"
        />
        <path
          d="M21.5067 10.1625L19.7742 11.685L18.7092 10.62L20.5167 9.02998L18.7467 5.96999L16.1667 6.83999C15.5624 6.33694 14.8779 5.93891 14.1418 5.66249L13.6093 3H10.0693L9.53676 5.66249C8.79472 5.93118 8.10647 6.32991 7.50427 6.83999L4.93177 5.96999L3.16178 9.02998L5.20177 10.8225C5.06302 11.5988 5.06302 12.3936 5.20177 13.17L3.16178 14.97L4.93177 18.03L7.51177 17.16C8.11612 17.663 8.80063 18.061 9.53676 18.3375L10.0693 21H11.0893V22.5H10.0693C9.72241 22.4997 9.38638 22.3792 9.11836 22.159C8.85034 21.9389 8.6669 21.6326 8.59927 21.2925L8.21677 19.4025C7.87721 19.2368 7.54899 19.0489 7.23427 18.84L5.41177 19.455C5.25679 19.5054 5.09475 19.5307 4.93177 19.53C4.66832 19.5318 4.40915 19.4632 4.18106 19.3314C3.95296 19.1995 3.7642 19.0092 3.63428 18.78L1.86428 15.72C1.68922 15.4192 1.62417 15.067 1.68027 14.7235C1.73637 14.3801 1.91012 14.0669 2.17178 13.8375L3.61178 12.5775C3.59678 12.3825 3.58928 12.195 3.58928 12C3.58928 11.805 3.60428 11.6175 3.61928 11.43L2.17178 10.1625C1.91012 9.93308 1.73637 9.61983 1.68027 9.2764C1.62417 8.93298 1.68922 8.58073 1.86428 8.27998L3.63428 5.21999C3.7642 4.99079 3.95296 4.80043 4.18106 4.66859C4.40915 4.53674 4.66832 4.46818 4.93177 4.46999C5.09475 4.46924 5.25679 4.49456 5.41177 4.54499L7.22677 5.15999C7.54406 4.95097 7.87478 4.76306 8.21677 4.59749L8.59927 2.7075C8.6669 2.36731 8.85034 2.06107 9.11836 1.84091C9.38638 1.62075 9.72241 1.50028 10.0693 1.5H13.6093C13.9561 1.50028 14.2921 1.62075 14.5602 1.84091C14.8282 2.06107 15.0116 2.36731 15.0793 2.7075L15.4617 4.59749C15.8013 4.7631 16.1295 4.95101 16.4442 5.15999L18.2667 4.54499C18.4217 4.49456 18.5838 4.46924 18.7467 4.46999C19.0102 4.46818 19.2694 4.53674 19.4975 4.66859C19.7256 4.80043 19.9143 4.99079 20.0442 5.21999L21.8142 8.27998C21.9893 8.58073 22.0543 8.93298 21.9982 9.2764C21.9422 9.61983 21.7684 9.93308 21.5067 10.1625Z"
          fill="black"
        />
      </g>
    </svg>
  );
};
