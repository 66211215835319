export const InformationIcon = () => {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none">
      <path
        d="M8.25 16C10.3717 16 12.4066 15.1571 13.9069 13.6569C15.4071 12.1566 16.25 10.1217 16.25 8C16.25 5.87827 15.4071 3.84344 13.9069 2.34315C12.4066 0.842855 10.3717 0 8.25 0C6.12827 0 4.09344 0.842855 2.59315 2.34315C1.09285 3.84344 0.25 5.87827 0.25 8C0.25 10.1217 1.09285 12.1566 2.59315 13.6569C4.09344 15.1571 6.12827 16 8.25 16ZM9.18 6.588L8.18 11.293C8.11 11.633 8.209 11.826 8.484 11.826C8.678 11.826 8.971 11.756 9.17 11.58L9.082 11.996C8.795 12.342 8.162 12.594 7.617 12.594C6.914 12.594 6.615 12.172 6.809 11.275L7.547 7.807C7.611 7.514 7.553 7.408 7.26 7.337L6.809 7.256L6.891 6.875L9.181 6.588H9.18ZM8.25 5.5C7.98478 5.5 7.73043 5.39464 7.54289 5.20711C7.35536 5.01957 7.25 4.76522 7.25 4.5C7.25 4.23478 7.35536 3.98043 7.54289 3.79289C7.73043 3.60536 7.98478 3.5 8.25 3.5C8.51522 3.5 8.76957 3.60536 8.95711 3.79289C9.14464 3.98043 9.25 4.23478 9.25 4.5C9.25 4.76522 9.14464 5.01957 8.95711 5.20711C8.76957 5.39464 8.51522 5.5 8.25 5.5Z"
        fill="#C4C4C4"
      />
    </svg>
  );
};
