export const SupportIcon = () => {
  return (
    <svg width="88" height="88" viewBox="0 0 88 88" fill="none">
      <g clipPath="url(#clip0_954_34373)">
        <path
          d="M44.0009 0C25.4484 0 10.3536 15.0948 10.3536 33.6472V64.7056C10.3536 68.9877 13.8368 72.4708 18.1182 72.4708C22.4003 72.4708 25.8835 68.9877 25.8835 64.7056V38.8236C25.8835 34.5422 22.4003 31.059 18.1182 31.059C17.2468 31.059 16.4223 31.2356 15.6388 31.5015C16.7432 16.8041 29.0283 5.17639 44.0009 5.17639C58.9654 5.17639 71.2471 16.7934 72.3616 31.4807C71.5828 31.2141 70.7536 31.059 69.8835 31.059C65.6014 31.059 62.1182 34.5422 62.1182 38.8236V64.7056C62.1182 68.9877 65.6014 72.4708 69.8835 72.4708C70.7952 72.4708 71.658 72.2842 72.4717 71.9941V75.059C72.4717 76.4871 71.3088 77.6472 69.8835 77.6472H51.2888C50.2166 74.6407 47.3705 72.4708 44.0009 72.4708C39.7194 72.4708 36.2363 75.954 36.2363 80.2354C36.2363 84.5168 39.7194 88 44.0009 88C47.3705 88 50.2166 85.8294 51.2888 82.8236H69.8835C74.1649 82.8236 77.6481 79.3405 77.6481 75.059C77.6481 59.4909 77.6481 49.2321 77.6481 33.6472C77.6481 15.0948 62.5533 0 44.0009 0V0Z"
          fill="url(#paint0_linear_954_34373)"
        />
        <path
          d="M82.8241 36.7121V66.8171C85.8299 65.7455 88.0005 62.8995 88.0005 59.5292V44C88.0005 40.6303 85.8299 37.7843 82.8241 36.7121Z"
          fill="url(#paint1_linear_954_34373)"
        />
        <path
          d="M0 44V59.5292C0 62.8996 2.17059 65.7456 5.17639 66.8171V36.7121C2.17059 37.7843 0 40.6303 0 44Z"
          fill="url(#paint2_linear_954_34373)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_954_34373"
          x1="10.3536"
          y1="88"
          x2="95.2795"
          y2="23.0564"
          gradientUnits="userSpaceOnUse">
          <stop offset="0.244792" stopColor="#125C4F" />
          <stop offset="0.65625" stopColor="#197E6C" />
          <stop offset="1" stopColor="#24B298" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_954_34373"
          x1="82.8241"
          y1="66.8171"
          x2="92.8796"
          y2="65.0881"
          gradientUnits="userSpaceOnUse">
          <stop offset="0.244792" stopColor="#125C4F" />
          <stop offset="0.65625" stopColor="#197E6C" />
          <stop offset="1" stopColor="#24B298" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_954_34373"
          x1="0"
          y1="66.8171"
          x2="10.0555"
          y2="65.0881"
          gradientUnits="userSpaceOnUse">
          <stop offset="0.244792" stopColor="#125C4F" />
          <stop offset="0.65625" stopColor="#197E6C" />
          <stop offset="1" stopColor="#24B298" />
        </linearGradient>
        <clipPath id="clip0_954_34373">
          <rect width="88" height="88" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
