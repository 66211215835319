import {FC} from 'react';
import styled from 'styled-components';
export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 27px;
  height: 27px;
  background: #f2fffd;
  box-shadow: 0 0 16px rgb(0 0 0 / 16%);
  border-radius: 2px;
`;
type TProps = {
  back?: boolean;
  color?: string;
};

export const BuyerIcon: FC<TProps> = ({back = true, color = 'black'}) => {
  return back ? (
    <Wrapper>
      <svg width="17" height="17" viewBox="0 0 17 17" fill="none">
        <path
          d="M8.44727 0.482422C4.02941 0.482422 0.447266 4.06457 0.447266 8.48242C0.447266 12.9003 4.02941 16.4824 8.44727 16.4824C12.8651 16.4824 16.4473 12.9003 16.4473 8.48242C16.4473 4.06457 12.8651 0.482422 8.44727 0.482422ZM8.44727 15.1253C4.77941 15.1253 1.80441 12.1503 1.80441 8.48242C1.80441 4.81457 4.77941 1.83956 8.44727 1.83956C12.1151 1.83956 15.0901 4.81457 15.0901 8.48242C15.0901 12.1503 12.1151 15.1253 8.44727 15.1253ZM9.29905 8.06814L8.84548 7.96278V5.56457C9.52405 5.65742 9.94369 6.08242 10.0151 6.60385C10.0241 6.67528 10.0848 6.72707 10.1562 6.72707H10.958C11.0419 6.72707 11.108 6.65385 11.1008 6.56992C10.9919 5.45742 10.0758 4.74314 8.85262 4.61992V4.03599C8.85262 3.95742 8.78834 3.89314 8.70977 3.89314H8.20798C8.12941 3.89314 8.06512 3.95742 8.06512 4.03599V4.62528C6.80084 4.74849 5.81155 5.44671 5.81155 6.75028C5.81155 7.95742 6.70084 8.53957 7.63477 8.76278L8.07584 8.87528V11.4235C7.28655 11.3181 6.84369 10.8967 6.75262 10.3289C6.74191 10.261 6.68119 10.211 6.61155 10.211H5.78655C5.70262 10.211 5.63655 10.2824 5.64369 10.3664C5.72405 11.3485 6.46869 12.2521 8.05798 12.3681V12.9289C8.05798 13.0074 8.12227 13.0717 8.20084 13.0717H8.70798C8.78655 13.0717 8.85084 13.0074 8.85084 12.9271L8.84727 12.361C10.2455 12.2378 11.2455 11.4896 11.2455 10.1467C11.2437 8.90742 10.4562 8.35385 9.29905 8.06814V8.06814ZM8.07405 7.77885C7.97405 7.75028 7.89012 7.72349 7.80619 7.68957C7.20262 7.47171 6.92227 7.11992 6.92227 6.66635C6.92227 6.01814 7.41334 5.64849 8.07405 5.56457V7.77885ZM8.84548 11.4289V9.04135C8.90084 9.05742 8.95084 9.06992 9.00262 9.08064C9.84727 9.33778 10.1312 9.69492 10.1312 10.2431C10.1312 10.9414 9.60619 11.361 8.84548 11.4289Z"
          fill="black"
          stroke="black"
          strokeWidth="0.1"
        />
      </svg>
    </Wrapper>
  ) : (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      style={{marginRight: '4px'}}>
      <path
        d="M8.44727 0.482422C4.02941 0.482422 0.447266 4.06457 0.447266 8.48242C0.447266 12.9003 4.02941 16.4824 8.44727 16.4824C12.8651 16.4824 16.4473 12.9003 16.4473 8.48242C16.4473 4.06457 12.8651 0.482422 8.44727 0.482422ZM8.44727 15.1253C4.77941 15.1253 1.80441 12.1503 1.80441 8.48242C1.80441 4.81457 4.77941 1.83956 8.44727 1.83956C12.1151 1.83956 15.0901 4.81457 15.0901 8.48242C15.0901 12.1503 12.1151 15.1253 8.44727 15.1253ZM9.29905 8.06814L8.84548 7.96278V5.56457C9.52405 5.65742 9.94369 6.08242 10.0151 6.60385C10.0241 6.67528 10.0848 6.72707 10.1562 6.72707H10.958C11.0419 6.72707 11.108 6.65385 11.1008 6.56992C10.9919 5.45742 10.0758 4.74314 8.85262 4.61992V4.03599C8.85262 3.95742 8.78834 3.89314 8.70977 3.89314H8.20798C8.12941 3.89314 8.06512 3.95742 8.06512 4.03599V4.62528C6.80084 4.74849 5.81155 5.44671 5.81155 6.75028C5.81155 7.95742 6.70084 8.53957 7.63477 8.76278L8.07584 8.87528V11.4235C7.28655 11.3181 6.84369 10.8967 6.75262 10.3289C6.74191 10.261 6.68119 10.211 6.61155 10.211H5.78655C5.70262 10.211 5.63655 10.2824 5.64369 10.3664C5.72405 11.3485 6.46869 12.2521 8.05798 12.3681V12.9289C8.05798 13.0074 8.12227 13.0717 8.20084 13.0717H8.70798C8.78655 13.0717 8.85084 13.0074 8.85084 12.9271L8.84727 12.361C10.2455 12.2378 11.2455 11.4896 11.2455 10.1467C11.2437 8.90742 10.4562 8.35385 9.29905 8.06814V8.06814ZM8.07405 7.77885C7.97405 7.75028 7.89012 7.72349 7.80619 7.68957C7.20262 7.47171 6.92227 7.11992 6.92227 6.66635C6.92227 6.01814 7.41334 5.64849 8.07405 5.56457V7.77885ZM8.84548 11.4289V9.04135C8.90084 9.05742 8.95084 9.06992 9.00262 9.08064C9.84727 9.33778 10.1312 9.69492 10.1312 10.2431C10.1312 10.9414 9.60619 11.361 8.84548 11.4289Z"
        fill={color}
        stroke={color}
        strokeWidth="0.1"
      />
    </svg>
  );
};
