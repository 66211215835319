export const GaleryIcon = () => {
  return (
    <svg width="29" height="25" viewBox="0 0 29 25" fill="none">
      <path
        d="M25.9557 0.5H6.64128C6.07211 0.5 5.52625 0.729869 5.12379 1.13904C4.72133 1.54821 4.49523 2.10316 4.49523 2.68182V4.86364H2.34918C1.78001 4.86364 1.23415 5.09351 0.831689 5.50268C0.429226 5.91185 0.203125 6.4668 0.203125 7.04545V22.3182C0.203125 22.8968 0.429226 23.4518 0.831689 23.861C1.23415 24.2701 1.78001 24.5 2.34918 24.5H21.6636C22.2328 24.5 22.7787 24.2701 23.1811 23.861C23.5836 23.4518 23.8097 22.8968 23.8097 22.3182V20.1364H25.9557C26.5249 20.1364 27.0708 19.9065 27.4732 19.4973C27.8757 19.0882 28.1018 18.5332 28.1018 17.9545V2.68182C28.1018 2.10316 27.8757 1.54821 27.4732 1.13904C27.0708 0.729869 26.5249 0.5 25.9557 0.5ZM6.64128 2.68182H25.9557V11.2386L24.6051 9.86682C24.4058 9.66417 24.1692 9.50341 23.9088 9.39373C23.6484 9.28405 23.3693 9.2276 23.0874 9.2276C22.8055 9.2276 22.5264 9.28405 22.266 9.39373C22.0056 9.50341 21.769 9.66417 21.5697 9.86682L18.8872 12.5941L12.9855 6.59409C12.5831 6.18523 12.0375 5.95556 11.4685 5.95556C10.8996 5.95556 10.354 6.18523 9.95156 6.59409L6.64128 9.95955V2.68182ZM21.6636 22.3182H2.34918V7.04545H4.49523V17.9545C4.49523 18.5332 4.72133 19.0882 5.12379 19.4973C5.52625 19.9065 6.07211 20.1364 6.64128 20.1364H21.6636V22.3182ZM25.9557 17.9545H6.64128V13.0455L11.4699 8.13636L18.1307 14.9082C18.3319 15.1126 18.6047 15.2275 18.8892 15.2275C19.1737 15.2275 19.4465 15.1126 19.6477 14.9082L23.0894 11.4091L25.9557 14.3245V17.9545ZM18.4446 6.5C18.4446 6.17636 18.539 5.85998 18.7158 5.59089C18.8927 5.32179 19.144 5.11205 19.4381 4.9882C19.7323 4.86434 20.0559 4.83194 20.3681 4.89508C20.6803 4.95822 20.9671 5.11407 21.1922 5.34292C21.4173 5.57177 21.5706 5.86334 21.6327 6.18076C21.6948 6.49818 21.6629 6.8272 21.5411 7.12621C21.4193 7.42522 21.213 7.68078 20.9483 7.86059C20.6836 8.04039 20.3724 8.13636 20.0541 8.13636C19.6272 8.13636 19.2178 7.96396 18.916 7.65708C18.6141 7.35021 18.4446 6.93399 18.4446 6.5Z"
        fill="white"
      />
    </svg>
  );
};
