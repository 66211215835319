import {FC} from 'react';

type TProps = {
  small?: boolean;
  onClick?: () => void;
  gray?: boolean;
  header?: boolean;
  big?: boolean;
  burger?: boolean;
  messages?: boolean;
};

export const CloseIcon: FC<TProps> = ({
  small = false,
  onClick = () => {},
  gray = false,
  header,
  big,
  burger,
  messages,
}) => {
  return messages ? (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.528264 0.778264C0.821157 0.48537 1.29603 0.48537 1.58892 0.778264L6.74969 5.93903L11.9105 0.778264C12.2034 0.48537 12.6782 0.48537 12.9711 0.778264C13.264 1.07116 13.264 1.54603 12.9711 1.83892L7.81036 6.99969L12.9711 12.1605C13.264 12.4534 13.264 12.9282 12.9711 13.2211C12.6782 13.514 12.2034 13.514 11.9105 13.2211L6.74969 8.06036L1.58892 13.2211C1.29603 13.514 0.821157 13.514 0.528264 13.2211C0.23537 12.9282 0.23537 12.4534 0.528264 12.1605L5.68903 6.99969L0.528264 1.83892C0.23537 1.54603 0.23537 1.07116 0.528264 0.778264Z"
        fill="#0D0C07"
      />
    </svg>
  ) : burger ? (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.146447 0.146447C0.341709 -0.0488155 0.658291 -0.0488155 0.853553 0.146447L5 4.29289L9.14645 0.146447C9.34171 -0.0488155 9.65829 -0.0488155 9.85355 0.146447C10.0488 0.341709 10.0488 0.658291 9.85355 0.853553L5.70711 5L9.85355 9.14645C10.0488 9.34171 10.0488 9.65829 9.85355 9.85355C9.65829 10.0488 9.34171 10.0488 9.14645 9.85355L5 5.70711L0.853553 9.85355C0.658291 10.0488 0.341709 10.0488 0.146447 9.85355C-0.0488155 9.65829 -0.0488155 9.34171 0.146447 9.14645L4.29289 5L0.146447 0.853553C-0.0488155 0.658291 -0.0488155 0.341709 0.146447 0.146447Z"
        fill="white"
      />
    </svg>
  ) : big ? (
    <svg width="23" height="23" viewBox="0 0 23 23" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.792893 0.838548C1.18342 0.448023 1.81658 0.448023 2.20711 0.838548L11.5 10.1314L20.7929 0.838548C21.1834 0.448023 21.8166 0.448023 22.2071 0.838548C22.5976 1.22907 22.5976 1.86224 22.2071 2.25276L12.9142 11.5457L22.2071 20.8385C22.5976 21.2291 22.5976 21.8622 22.2071 22.2528C21.8166 22.6433 21.1834 22.6433 20.7929 22.2528L11.5 12.9599L2.20711 22.2528C1.81658 22.6433 1.18342 22.6433 0.792893 22.2528C0.402369 21.8622 0.402369 21.2291 0.792893 20.8385L10.0858 11.5457L0.792893 2.25276C0.402369 1.86224 0.402369 1.22907 0.792893 0.838548Z"
        fill="#0D0C07"
      />
    </svg>
  ) : header ? (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.77851 1.77826C2.0714 1.48537 2.54627 1.48537 2.83917 1.77826L7.99994 6.93903L13.1607 1.77826C13.4536 1.48537 13.9285 1.48537 14.2214 1.77826C14.5143 2.07116 14.5143 2.54603 14.2214 2.83892L9.0606 7.99969L14.2214 13.1605C14.5143 13.4534 14.5143 13.9282 14.2214 14.2211C13.9285 14.514 13.4536 14.514 13.1607 14.2211L7.99994 9.06036L2.83917 14.2211C2.54627 14.514 2.0714 14.514 1.77851 14.2211C1.48561 13.9282 1.48561 13.4534 1.77851 13.1605L6.93928 7.99969L1.77851 2.83892C1.48561 2.54603 1.48561 2.07116 1.77851 1.77826Z"
        fill="#262626"
      />
    </svg>
  ) : small ? (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      onClick={() => onClick()}>
      <path
        d="M0.154 11.6763L4.458 5.93227L0.314 0.396269H2.698L5.626 4.44427L8.554 0.396269H10.954L6.81 5.93227L11.098 11.6763H8.698L5.626 7.45227L2.57 11.6763H0.154Z"
        fill="white"
      />
    </svg>
  ) : gray ? (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0156 2.24566L10.5756 0.805664L6.01562 5.36566L1.45562 0.805664L0.015625 2.24566L4.57562 6.80566L0.015625 11.3657L1.45562 12.8057L6.01562 8.24566L10.5756 12.8057L12.0156 11.3657L7.45562 6.80566L12.0156 2.24566Z"
        fill="#999999"
      />
    </svg>
  ) : (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      onClick={() => onClick()}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7781 2.41814C17.1086 2.08769 17.1086 1.55194 16.7781 1.22149C16.4477 0.891051 15.9119 0.891051 15.5815 1.22149L8.99997 7.80302L2.41848 1.22152C2.08803 0.89108 1.55228 0.89108 1.22183 1.22152C0.891392 1.55197 0.891391 2.08772 1.22183 2.41817L7.80333 8.99966L1.22179 15.5812C0.891342 15.9116 0.891341 16.4474 1.22178 16.7778C1.55223 17.1083 2.08798 17.1083 2.41843 16.7778L8.99997 10.1963L15.5815 16.7779C15.912 17.1083 16.4477 17.1083 16.7782 16.7779C17.1086 16.4474 17.1086 15.9117 16.7782 15.5812L10.1966 8.99966L16.7781 2.41814Z"
        fill="#0D0C07"
      />
    </svg>
  );
};
