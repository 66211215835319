import {FC} from 'react';

type TProps = {
  size: string;
};

export const FacebookIcon: FC<TProps> = ({size = '50'}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 50 50" fill="none">
      <g filter="url(#filter0_d_1178_31501)">
        <rect x="9" y="3.03345" width="32" height="32" rx="16" fill="#1781FE" />
      </g>
      <path
        d="M26.5448 27.9925V19.8184H29.4267L29.8581 16.6327H26.5447V14.5988C26.5447 13.6765 26.8137 13.048 28.203 13.048L29.9747 13.0472V10.198C29.6683 10.1593 28.6165 10.0725 27.393 10.0725C24.8383 10.0725 23.0894 11.5571 23.0894 14.2834V16.6327H20.2002V19.8184H23.0894V27.9924H26.5448V27.9925Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_d_1178_31501"
          x="0.566045"
          y="0.733278"
          width="48.8679"
          height="48.8679"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="6.13379" />
          <feGaussianBlur stdDeviation="4.21698" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0823529 0 0 0 0 0.454902 0 0 0 0 0.937255 0 0 0 0.21 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1178_31501"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1178_31501"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
